@import './variables';

$tokens_primary: #9e00ff;

.tokens {
  border-radius: 32px;
  border: 1px solid $text5;
  background: radial-gradient(
    298.23% 61.33% at 48.26% -5.97%,
    $tokens_primary 0%,
    $tokens_primary 6.18%,
    #171643 17.74%,
    #131315 29.84%,
    #131315 100%
  );
  max-width: 742px;
  width: 80%;

  display: flex;
  flex-direction: column;
  padding: 24px 0;
  & > div {
    padding-left: 31px;
    padding-right: 31px;
  }

  .switch_tab {
    display: grid;
    grid-template-columns: min-content min-content min-content;
    grid-gap: 24px;
    border-bottom: 1px solid $text5;
    .is_active {
      position: relative;
      color: $primary1;
      &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 2px;
        background-color: $primary1;
      }
    }
    button {
      white-space: nowrap;
      padding: 0 0 8px;
      &:first-child {
        padding-left: 0;
      }
      &:hover {
        color: $primary1;
      }
    }
  }
  section.action {
    justify-content: center;
    margin-top: 36px;
    button {
      width: 219px;
    }
  }
  .LBP_modal {
    white-space: pre-wrap;
    display: grid;
    grid-gap: 36px;
    margin: 36px 0;
    section {
      display: flex;
    }
    section.info {
      .card {
        background: rgba(255, 255, 255, 0.08);
        border-radius: 14px;
        padding: 22px 20px;
        width: 48%;
      }
    }

    section.tokens_icon {
      align-items: center;
      color: $text2;
      font-size: 13px;
      padding: 0 60px;

      div {
        img {
          margin-bottom: 20px;
        }
        & > * {
          margin: auto;
          display: flex;
          justify-content: center;
          text-align: center;
        }
      }
    }
  }
  .airdrop_modal {
    margin: 36px 0;
    display: grid;
    grid-gap: 20px;
    .card {
      background: rgba(255, 255, 255, 0.08);
      border-radius: 14px;
      padding: 14px 20px;
    }
    .label {
      font-size: 12px;
      color: $text3;
    }
  }
  .token_info_modal {
    padding: 38px;
    display: flex;
    .ring {
      margin-left: -50px;
    }
  }
  .confirm_modal {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 36px;
  }
  .app_body {
    width: 80%;
    max-width: 552px;
    height: auto;
    min-height: unset;
    outline: none;
    padding: 24px 32px;

    .default_button {
      margin-top: 36px;
    }
  }
  .translucent_card {
    width: 100%;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 14px;
    padding: 12px;

    svg,
    img {
      height: 24px;
      width: 24px;
    }
  }
  .card_frame {
    flex-grow: 1;
    font-size: 14px;
    label {
      margin-bottom: 4px;
    }
  }
}

.pending_modal {
  border: 1px solid $text5;
  display: grid;
  grid-gap: 24px;
  justify-content: center;
  grid-template-columns: 100%;
  &__loading {
    width: 48px;
    height: 48px;
    margin: 0 auto;
  }
}
