.app_body {
  width: 480px;
  background: #000000;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 32px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px 32px 28px 32px;
  position: relative;
  min-height: 400px;
  font-family: Roboto;

  @media #{$mobile} {
    // flex: 1;
    // width: 100%;
    width: $mobileCenterWidth;
    height: 100%;
    border-radius: 20px;
    justify-content: flex-start;
    min-height: 100%;
    // padding-bottom: 100px;
    overflow: auto;
  }
}
.dots {
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
}
