.transction-submitted {
    text-align: center;
    @media #{$desktop} {
    }
    @media #{$mdWidth} {
    }
    @media #{$smWidth} {
        width: 95%;
        min-width: 320px;
    }
    @media #{$xsWidth} {
        width: 95%;
        min-width: 320px;
    }
    &__logo {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        margin: 24px auto;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(250, 208, 106, 0.5);

        @media #{$smWidth} {
            width: 80px;
            height: 80px;
        }

        svg {
            fill: #000;
        }
    }

    .modal__title {
        margin: 0;
    }

    &__link {
        text-align: center;
        margin: 12px 0;
    }

    &__tip {
        margin-top: 13px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #22292f;
        line-height: 28px;
        max-width: 300px;
        text-align: left;
    }

    &__loading {
        display: flex;
        justify-content: center;

        img {
            width: 24px;
            height: 24px;
            -webkit-animation: loading 3s linear infinite;
        }
    }

    &__status {
        display: flex;
        justify-content: center;

        img {
            width: 48px;
            height: 48px;
        }
    }

    &__text {
        font-size: 14px;
        color: #808080;
        margin: 0px 0 30px;
        text-align: center;
    }

    &__btn {
        background: #0f8c20;
        border-radius: 100px;
        width: fit-content;
        margin-top: 30px;
        color: #ffffff;
        padding: 0 16px;
        margin: auto;

        @media #{$smWidth} {
            margin-top: 24px;
        }
    }

    &__title {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        color: #ffffff;
    }

    input {
        background: rgba(255, 255, 255, 0.08);
        border: 1px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 14px;
        width: 376px;
        height: 48px;
        margin: 32px 0 0 0;
        padding: 0 20px;
        outline: none;
        color: #ffffff;
        font-size: 16px;
        font-family: Roboto-Regular;
    }

    button {
        color: #ffffff;
        margin-top: 20px;
    }

    .error {
        color: rgba(255, 0, 0, 1);
        font-size: 13px;
        text-align: left;
        margin-top: 8px;
    }
}

@keyframes loading {
    to {
        transform: rotate(360deg);
    }
}
