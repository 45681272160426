:root {
  --color: rgba(34, 41, 47, 1);
  --text: color(#fff a(70%));
  --error: #f45959;
  --bg: #000;
  --blue: blue;
  --border: color(#fad06a a(20%));
  --green: #86ba6e;
  --link: #fd7749;
  --red: #fa6a6a;
  --yellow: #fad06a;
  --yellowHover: #e4be62;
  --yellowDisabled: color(#fad06a a(30%));
  --transitionDefault: 0.3s;
}
$primary1: #9867ff;
$primary2: #7433ff;

$white2: rgba(255, 255, 255, 0.2);
$white6: rgba(255, 255, 255, 0.6);

$text1: #ffffff;
$text2: #cccccc;
$text3: #999999;
$text4: #727272;
$text5: #333333;

$bg1: #000000;
$bg2: #191919;
$bg3: #252525;
$bg4: #303030;
$bg5: #a1a1a1;

$headerHeight: 88px;
$headerHeightMobile: 90px;

$desktop: '(min-width: 1200px)';
$mdWidth: '(min-width:747px) and (max-width: 1199px)';
$smWidth: '(min-width:359px)and (max-width: 747px)';
$xsWidth: '(max-width: 359px)';

$mdHeight: '(max-height: 880px)';

$mobile: '(max-width: 747px)';
$noMobile: '(min-width: 748px)';

$mobileCenterWidth: calc(100% - 32px);