@import './_variables.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;

  &.scroll-behavior-off {
    scroll-behavior: auto;
  }
}

#root {
  height: 100%;
  overflow-y: auto;
}

body {
  background: #1C1C1F;
  color: #ffffff;
  height: 100%;
  // font-family: 'Montserrat', sans-serif;
}

a {
  color: #ffffff;
  text-decoration: underline;
  transition: var(--transitionDefault);

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
}

::selection {
  color: #000000;
  background-color: #ffffff;
}

img {
  border: 0;
  display: block;
  position: relative;
}

li {
  list-style: none;
  position: relative;
}

svg {
  display: block;
  fill: var(--color);
  position: relative;
  transition: var(--transitionDefault);
}

table {
  border: 0;
  border-collapse: collapse;
}

hr {
  height: 1px;
  border: 0;
  background-color: #fff;
  opacity: 0.1;
}

@font-face {
  font-family: 'Jost';
  src: url('../../assets/fonts/Jost-VariableFont_wght.ttf');
}

@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/Roboto-Light.ttf');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('../../assets/fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto-bold';
  src: url('../../assets/fonts/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'Futura PT';
  src: url('../../assets/fonts/FuturaPT-Demi.ttf');
}

.align-center {
  text-align: center;
}

.button-row {
  width: 100%;
  display: flex;
  gap: 12px;
}

/*
-----------------------------------------------------------------------------*/
.notsel {
  user-select: none;
}

.h1 {
  font-size: 48px;
  line-height: 60px;
  font-weight: 400;

  @media #{$mdWidth} {
    font-size: 36px;
    line-height: 48px;
  }

  @media #{$smWidth} {
    font-size: 24px;
    line-height: 36px;
  }
}

.h2 {
  font-size: 36px;
  line-height: 48px;
  font-weight: 400;
}

.h3 {
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
}

.status {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #22292f;
  margin-top: 8px !important;
}

.h4 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
}

main,
header,
footer,
section,
article,
aside,
time,
nav {
  display: block;
  position: relative;
}

.wrapper,
.main {
  height: 100%;
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

/* hidden
-----------------------------------------------------------------------------*/
.hide {
  display: none !important;
}

.visuallyhidden {
  position: absolute !important;

  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  border: 0 !important;
  padding: 0 !important;

  clip: rect(0 0 0 0) !important;
  overflow: hidden !important;
}

a[href^='tel'],
.white-space-nowrap {
  white-space: nowrap;
}

.opacity-60 {
  opacity: 0.6;
}

.opacity-40 {
  opacity: 0.4;
}

.fz-14 {
  font-size: 14px;
}

.fz-20 {
  font-size: 20px;
}

.color-gray {
  opacity: 0.2;
}

.value {
  font-size: 18px;
  font-family: HelveticaNeue-Bold, HelveticaNeue;
  font-weight: bold;
  color: #22292f;
}

.withdraw-title {
  font-size: 12px;
  font-family: HelveticaNeue;
  color: #7a7f82;
}

.gold-color {
  color: var(--yellow);
}

.language {
  border-radius: 5px;
  font-size: 14px;
  font-family: HelveticaNeue;
  color: #22292f;
  padding: 10px;
  cursor: pointer;
  z-index: 999;
  display: flex;
  align-items: center;
  position: relative;

  img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .language-items {
    display: none;
    flex-direction: column;
    background: #f2f0eb;
    box-shadow: 0px 0px 6px 0px rgba(122, 127, 130, 0.2);
    border-radius: 5px 5px 0px 0px;
    padding: 12px 10px;
    right: 50%;
    position: absolute;
    bottom: 80%;
    transform: translate(50%);

    p {
      color: #22292f;
      width: 90px;
      padding: 5px 0;
      text-align: center;

      &:hover {
        background-color: rgba(212, 233, 226, 1);
        border-radius: 5px;
      }
    }
  }

  &:hover {
    display: flex;

    .language-items {
      display: flex;
    }
  }
}

.satellite {
  position: fixed;
  width: 252px;
  bottom: 0;
  right: 0;
}

@media #{$smWidth} {
  .satellite {
    position: absolute;
    width: 252px;
    bottom: 0;
    right: 50%;
    transform: translate(50%);
  }
  .language {
    background: transparent;
    border-radius: 5px;
    font-size: 14px;
    font-family: HelveticaNeue;
    color: #22292f;
    padding: 10px;
    cursor: pointer;
    z-index: 999;
    display: flex;
    align-items: center;
    position: relative;

    img {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }

    .language-items {
      display: none;
      flex-direction: column;
      background: #f2f0eb;
      box-shadow: 0px 0px 6px 0px rgba(122, 127, 130, 0.2);
      border-radius: 5px 5px 0px 0px;
      bottom: 40px;
      position: absolute;
      padding: 12px 10px;
      right: 50%;
      transform: translateX(50%);

      p {
        color: #22292f;
        width: 90px;
        padding: 5px 0;
        text-align: center;

        &:hover {
          background-color: rgba(212, 233, 226, 1);
          border-radius: 5px;
        }
      }
    }

    &:hover {
      display: flex;

      .language-items {
        display: flex;
      }
    }
  }
}

.right {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

canvas {
  width: 100%;
  height: 100vh;
}

.default_modal {
  width: 480px;
  padding-top: 24px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0) 100%), #000000;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;
  position: relative;
  z-index: 10;
  max-width: 100%;

  button {
    // width: 240px;
    // height: 48px;
    // border: 1px solid #ffffff;
    // font-family: Roboto;
    // font-style: normal;
    // font-weight: 500;
    // font-size: 16px;
    // outline: none;
    // border-radius: 100px;
    // color: #ffffff;
    // cursor: pointer;
    // display: flex;
    // align-items: center;
    // position: relative;

    // button {
    //   width: 240px;
    //   height: 48px;
    //   border: 1px solid #ffffff;
    //   font-family: Roboto;
    //   font-style: normal;
    //   font-weight: 500;
    //   font-size: 16px;
    //   outline: none;
    //   border-radius: 100px;
    //   color: #ffffff;
    //   background-color: transparent;
    //   cursor: pointer;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   @media #{$mobile} {
    //     width: 100%;
    //   }

    //   &:hover {
    //     border: 1px solid #9867ff;
    //   }

    //   &:disabled {
    //     border: 1px solid rgba(255, 255, 255, 0.3);
    //     color: rgba(255, 255, 255, 0.3);
    //   }

    //   img {
    //     height: 20px;
    //     margin-right: 20px;
    //   }
    // }

    // &:hover {
    //   border: 1px solid #9867ff;
    // }

    // &:disabled {
    //   border: 1px solid rgba(255, 255, 255, 0.3);
    //   color: rgba(255, 255, 255, 0.3);
    // }

    img {
      height: 20px;
      margin-right: 20px;
    }
  }

  @media #{$mobile} {
    width: $mobileCenterWidth;
    // height: 100%;
    // flex: 1;
    overflow-y: auto;
    padding: 20px;
    justify-content: center;
    font-size: 24px;
    border-radius: 20px;
    max-height: calc(100% - 50px);
    overflow-x: hidden;
  }

  p {
    text-align: center;
    line-height: 148.69%;
  }

  &__icon {
    width: 45px;
    height: 45px;
    margin-bottom: 20px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 70px 0 32px;
    align-items: center;
    display: flex;
    margin-bottom: 14px;

    &__dropdown {
      height: 32px;
      width: 126px;
    }
  }

  &__title {
    font-family: Futura PT;
    margin-bottom: 28px;
    font-size: 22px;

    //@media #{$smWidth} {
    @media #{$smWidth} {
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 124%;
    }
  }

  &__loading {
    width: 60px;
    height: 60px;
    -webkit-animation: loading 3s linear infinite;
  }
}

.claim_list_modal {
  @media #{$noMobile}{
    width: 680px!important;
  }
  @media #{$mobile}{
    width: 100%;
    height: calc(100vh - 122px)!important;
    display: block;
    overflow: hidden;
    z-index: 1!important;
    border-bottom-left-radius: 0!important;
    border-bottom-right-radius: 0!important;
  }
  &__header {
    display: flex;
    width: 600px;
    justify-content: space-between;
    margin-bottom: 24px;

    p {
      font-family: Futura PT;
      font-size: 28px;
    }
  }
}

.token_button {
  font-size: 14px;
  background: linear-gradient(96.37deg, #620aab 18.99%, #221453 92.88%);
  border-radius: 100px;
  padding: 0 27px;
  text-decoration: none;
  height: 36px;
  line-height: 36px;
  border: none;
  &:hover {
    background: linear-gradient(96.37deg, #7433FF 18.99%, #221453 92.88%);
  }
  @media #{$mobile} {
    display: none;
  }
}

.claim_button {
  @media #{$mobile} {
    display: flex;
  }
}


.submitted {
  padding: 32px;
  border: 1px solid $text5;
  @media #{$mobile} {
    flex: 1;
    margin-bottom: 0;
    width: -webkit-fill-available;
  }

  > p {
    font-size: 18px;
    @media #{$mobile} {
      font-size: 24px !important;
      width: 259px;
    }
  }

  img {
    width: 32px;
    height: 32px;
  }

  a {
    color: $primary1;
    margin-top: 32px;
    font-size: 16px;
    text-decoration: none;
    line-height: 148.69%;
    text-align: center;

    &:hover {
      color: $primary2;
    }

    @media #{$mobile} {
      font-size: 16px;
      margin-top: 36px;
    }
  }

  button {
    margin-top: 32px;

    @media #{$mobile} {
      margin-top: auto;
      margin-bottom: 100px;
      width: 100%;
      height: 48px;
      font-size: 18px;
    }
  }
}

.modal-wallets {
  width: 480px !important;
  @media #{$mobile} {
    justify-content: flex-start;
    padding: 22px 0;
    height: unset;
    flex: none;
    width: $mobileCenterWidth !important;
    >svg {
      top: 31px;
    }
    .default_button_outlined img {
      width: 20px !important  ;
    }
  }

  > p {
    margin-bottom: 48px;
    @media #{$mobile} {
       padding-left: 20px;
       text-align: left;
       width: 100%;
       margin-bottom: 32px;
    }
  }

  button {
    width: -webkit-fill-available;
    margin: 0 20px;
  }

  button:hover {
    border: 1px solid #9867ff;
    color: #9867ff;
  }
}

.modal-switch {
  @media #{$smWidth} {
    width: fit-content;
    justify-content: flex-start;
    padding: 24px 24px 81px 24px;
    flex: 0;
    margin-bottom: 0;

    p {
      font-size: 14px;
    }
  }

  a {
    color: #ffffff;
    text-decoration: underline;

    &:hover {
      border: 1px solid #9867ff;
      color: #9867ff;
    }
  }
}

.modal_profile {
  .default_modal__title {
    font-size: 16px;
    font-family: 'Roboto';
  }

  @media #{$mobile} {
    justify-content: flex-start;
    // padding: 52px 0 120px;
    .btn_group {
      width: -webkit-fill-available;
      // margin: 12px 60px -8px 60px;

      & > * {
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }
  }

  &__address {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 148.69%;
    display: flex;
    align-items: center;
    margin-left: -14px;
    margin-right: -14px;

    & > * {
      margin-left: 14px;
      margin-right: 14px;
    }

    p {
      font-size: 24px;
    }

    .dot {
      width: 28px;
      height: 28px;
      background: linear-gradient(
                      135deg,
                      #ffffff 4.17%,
                      rgba(255, 255, 255, 0) 75%
      );
      border: 0.6px solid #ffffff;
      box-sizing: border-box;
      border-radius: 14px;
      margin-right: 6px;
    }
  }

  &__copy {
    font-family: IBM Plex Mono;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.6);
    display: flex;
    align-items: center;
    margin-left: -6px;
    margin-right: -6px;
    margin-top: 12px;

    & > * {
      margin-left: 6px;
      margin-right: 6px;
    }

    svg {
      width: 16px;
      cursor: pointer;

      &:hover {
        g {
          opacity: 1;
        }
      }
    }
  }
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  left: auto;
  cursor: pointer;
  z-index: 9;
}

.back-btn {
  width: 20px;
  position: absolute;
  top: 24px;
  left: 24px;
  cursor: pointer;
  z-index: 9;
}

.chain_tip {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-family: Roboto-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 148.69%;
  color: #ffffff;

  p:nth-child(1) {
    color: rgba(255, 255, 255, 0.6);
  }

  @media #{$smWidth} {
    width: 100%;
  }
}

.line {
  width: 416px;
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
  margin: 18px auto 24px auto;

  @media #{$smWidth} {
    width: 100%;
  }
}

.btn_group {
  display: flex;
  width: -webkit-fill-available;
  margin-left: -6px;
  margin-right: -6px;

  & > * {
    margin-left: 6px;
    margin-right: 6px;
  }

  // @media #{$smWidth} {
  //   width: inherit;
  // }

  // button {
  //   display: inherit;
  //   @media #{$smWidth} {
  //     margin-top: 0;
  //   }
  // }

  @media #{$smWidth} {
    flex-direction: column;
    margin: -12px;
    width: 100%;
    & > * {
      margin: 0;
      margin-bottom: 16px;
    }
  }
}

.error_modal {
  @media #{$noMobile} {
    width: 480px !important;
    padding: 44px 60px 40px;
    border-radius: 32px;
  }
  @media #{$mobile} {
    padd
    .btn_group {
      margin-top: auto;
      // margin-bottom: 100px;

      button {
        width: unset;
      }
    }
  }

  p {
    font-size: 18px;
    line-height: 148.69%;
    width: 100%;
    word-break: break-all;

    @media #{$smWidth} {
      font-size: 24px;
    }
  }

  img {
    width: 32px;
    height: 32px;
    margin-bottom: 20px;

    @media #{$mobile} {
      margin-top: 40px;
    }
  }
}

.confirm_modal {
  @media #{$noMobile} {
    padding: 40px 60px 32px;
  }
  &__loading {
    width: 40px;
    height: 40px;
    -webkit-animation: loading 3s linear infinite;
    margin-bottom: 10px;
  }
  .color-gray {
    opacity: .4;
    font-size: 14px;
    line-height: 148.69%;
  }

  img {
    @media #{$mobile} {
      margin-top: 40px;
    }
  }

  p {
    margin-top: 16px;
    font-size: 18px;
    line-height: 148.69%;
  }
}

.transactions {
  width: 418px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 22px;
  padding: 16px 24px;
  font-family: Roboto;
  font-style: normal;
  font-size: 16px;
  max-height: 240px;
  overflow: auto;
  position: relative;
  @media #{$mobile} {
    width: 100%;
    p {
      text-align: justify;
    }
  }

  @media #{$mobile} {
    width: 90%;
  }

  .clear {
    position: absolute;
    top: 16px;
    right: 24px;
    font-size: 14px;
    opacity: 0.8;
    text-decoration: none;

    &:hover {
      opacity: 1;
      text-decoration: underline;
    }
  }

  ul {
    padding-top: 8px;

    li {
      a {
        color: #ffffff;
        font-size: 14px;
        text-decoration: underline;

        &:hover {
          color: #9867ff;
        }
      }

      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
    }
  }
}

button {
  background: transparent;
  border: none;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  font-family: Roboto-Regular;
  font-style: normal;
  font-size: 16px;
  color: #fff;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  font-weight: 500;
  padding: 0 24px;

  img {
    margin-right: 12px;
    width: 20px;
    height: 20px;
    margin-bottom: 0;
  }

  // &:hover {
  //   border: 1px solid #9867ff;
  //   color: #9867ff;
  // }

  // &:disabled {
  //   border: 1px solid rgba(255, 255, 255, 0.3);
  //   color: rgba(255, 255, 255, 0.3);
  //   opacity: 1;
  // }
}

.small {
  height: 32px;
  padding: 0 19px;
  width: fit-content;
}

.input_error {
  border: 1px solid rgba(255, 0, 0, 0.6) !important;
}

.fetch__loading {
  width: 32px;
  height: 32px;
  margin: 45px auto;
  -webkit-animation: loading 3s linear infinite;
}

.loading {
  width: 12px;
  height: 12px !important;
  margin-right: 0 !important;
  -webkit-animation: loading 3s linear infinite;
}

.loading-mid {
  width: 20px;
  height: 20px !important;
  -webkit-animation: loading 3s linear infinite;
}

.token__frame {
  width: 100%;
  height: 48px;
  padding: 0 32px;
  display: flex;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  &.disabled {
    opacity: .5;
    cursor: auto !important;
  }
  p {
    color: #ffffff;
  }

  &__extra {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    margin-top: -2px;
    margin-bottom: -2px;

    & > * {
      margin-top: 2px;
      margin-bottom: 2px;
      text-align: left!important;
    }

    p:nth-child(2) {
      font-size: 12px;
      color: rgba(255, 255, 255, 0.6);
    }
  }

  &:hover {
    cursor: pointer;

    p {
      color: #9867ff;
    }
  }
}

.mini {
  display: none !important;
  @media #{$mobile} {
    display: inherit !important;
  }
}

.giant {
  display: inherit !important;
  @media #{$mobile} {
    display: none !important;
  }
}

.auto_row {
  display: flex;
  align-items: center;

  img {
    width: 36px;
  }
}

.row_between {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.row {
  display: flex;
  align-items: center;
}

.impact_frame {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 12px;

  & > * {
    margin-left: 16px;
    margin-right: 16px;
  }

  button {
    width: 80px;
    background-color: rgba(var(--white), 0.08);
    height: 50px;
    border-radius: 14px;
  }
}

.impact_helper {
  color: rgba(var(--white), 0.6);
}

.impact_input {
  outline: none;
  width: 120px;
  height: 50px;
  border: 1px solid transparent;
  color: #ffffff;
  background-color: rgba(var(--white), 0.08);
  padding: 20px;
  font-size: 16px;
  font-family: Roboto-Regular;
  border-radius: 14px;

  &:hover,
  &:focus {
    border: 1px solid #9867ff;
    border-radius: 14px;
  }

  &:disabled {
    border: unset;
  }

  @media #{$mobile} {
    padding-right: 20px;
  }
}

.impact_active {
  border: 1px solid #9867ff;
}

.impact_icon {
  position: absolute;
  margin-top: 0px;
  right: 42px;
}

.slippage_error {
  color: rgba(255, 0, 0, 0.6);
  font-size: 16px;
  width: 100%;
  text-align: left !important;
  margin-left: 80px;
}

.text {
  &_small_gray {
    font-size: 14px;
    color: $text3;
  }

  &_title {
    font-family: 'Futura PT';
  }

  &_small {
    font-size: 12px;
  }

  &_gray {
    color: $text3;
  }

  &_22 {
    font-size: 22px;
  }
  &_16 {
    font-size: 16px;
  }
  &_14 {
    font-size: 14px;
  }
  &_bold {
    font-weight: 500;
  }
  &_regular{
    font-family: Roboto-Regular;
  }
}

label {
  display: flex;
  align-items: center;
  user-select: none;

  i {
    display: block;
    width: 16px;
    height: 16px;
    border: 1px solid #9867ff;
    box-sizing: border-box;
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    margin-right: 25px;
  }

  input {
    display: none;

    &:checked + i {
      border: 1px solid #9867ff;
      background: url('../icon/checkbox.svg') no-repeat;
      background-size: 80%;
      background-position: center;
    }
  }

  span {
    font-family: 'Helvetica Neue';
    font-weight: 500;
    font-size: 14px;
    line-height: 17.09px;
  }
}

.modal_deployed {
  padding: 24px 32px 32px 32px;

  button {
    width: 100%;
  }

  &__extras {
    width: 100%;
    margin-top: -20px;
    margin-bottom: 18px;

    & > * {
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }
}

.import_btn {
  width: 84px;
  height: 36px;
  margin-left: auto;
  margin-right: 0;
}

.import_heard {
  width: 416px;
  height: 132px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 22px;
  background-color: rgba(0, 0, 0, 0.2);
  margin: auto;
  padding: 24px;

  &__token {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 133.5%;
    margin-left: -12px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    color: #ffffff;

    & > * {
      margin-right: 12px;
    }
  }

  a {
    margin-top: 12px;
    color: rgba(152, 103, 255, 1);
  }
}

.import_bottom {
  width: 416px;
  height: 232px;
  margin: auto;
  background: rgba(152, 103, 255, 0.08);
  border: 1px solid rgba(152, 103, 255, 0.6);
  box-sizing: border-box;
  border-radius: 22px;
  margin-top: 16px;
  padding: 24px 68px 30px 24px;
  color: #ffffff;

  > h5 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 148%;
  }

  > p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 132%;
  }

  & > * {
    margin-top: 12px;
    margin-bottom: 12px;
  }
}

.title {
  font-family: Futura PT;
  margin-bottom: 28px;
  font-size: 18px;

  @media #{$smWidth} {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 124%;
  }
}

.default_input {
  width: 100%;

  input {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 14px;
    width: 100%;
    padding: 14px 20px;
    border: none;
    color: $text1;
    outline: none;
    margin-top: 4px;

    &:hover,
    &:focus {
      border: 1px solid #9867ff;
      border-radius: 14px;
    }

    &:disabled {
      border: unset;
    }
  }
}

.btn_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: unset;
  background-color: transparent !important;
  padding-left: unset !important;
  height: unset !important;
}

.btn_extra {
  font-size: 12px;
  -webkit-transform: scale(0.90);
}

.disabled_frame {
  top: 0;
  width: 100%!important;
  height: 100%!important;
  position: absolute;
  z-index: 1;
  background-color: rgba(38, 38, 38, 0.4);
  cursor: not-allowed;
}

.mask {
  position: relative;
  opacity: 0.4 !important;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
  }
}

.wrong_network {
  font-family: Roboto-Regular;
  height: 32px;
  padding: 0 24px;
  border-radius: 10px;
  background-color: rgb(253, 64, 64);
  display: flex;
  align-items: center;
  justify-content: center;
}
.mw100 {
  max-width: 100%;
}
.mobile-ml10 {
  @media #{$smWidth} {
    margin-left: 10px !important;
  }
}
