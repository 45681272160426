.font-24 {
  font-size: var(--accent);
  line-height: var(--line-118);
}

.font-20 {
  font-size: var(--headline-1);
  line-height: var(--line-148);
}

.font-18 {
  font-size: var(--headline-2);
  line-height: var(--line-148);
}

.font-16 {
  font-size: var(--body-1);
  line-height: var(--line-148);
}

.font-14 {
  font-size: var(--body-2);
  line-height: var(--line-148);
}

.font-12 {
  font-size: var(--caption);
  line-height: var(--line-148);
}
