@import '../../../assets/css/variables.scss';

$padding: 32px;

.divider {
  height: 0;
  border-bottom: 1px solid $text5;
  width: calc(100% + #{$padding} + #{$padding});
  margin: 0 #{-$padding};
}

.body {
  width: 420px;
  padding: 20px #{$padding};
  min-height: unset;
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 20px;
}

.button {
  border-color: #ffffff;
  color: #ffffff;
}

.accordion {
  width: 100%;
  button {
    padding: 0;
  }
  & > div {
    margin-top: 16px;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 16px;
  }
}

.claim {
  padding: 0;
  color: $primary1;
  width: auto;
  &:hover,
  :focus,
  :active {
    color: $primary2;
  }
}

.num {
  font-size: 40px;
  font-weight: 500;
  line-height: 59px;
  letter-spacing: 0.01em;
  margin-right: 12px;
  text-align: center;
}

.unit {
  white-space: nowrap;
  text-align: center;
}

.card {
  border: 1px solid $text5;
  padding: 4px 8px;
  font-size: 10px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  svg {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
}

.title {
  font-family: Futura PT;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
}
