@use "../../helpers/style/mixin";
@use "../../helpers/style/media";

.button {
  display: flex;
  flex-direction: row;
  align-items: center;

  font-family: var(--primary-font);
  font-weight: var(--medium);
  line-height: var(--line-118);
  text-decoration: none;

  border: 0;

  background-color: transparent;

  cursor: pointer;

  &:disabled {
    cursor: none;
    pointer-events: none;
  }
}

.icon {
  position: relative;
}

.icon span {
  @include mixin.visuallyHidden();
}

.medium {
  font-size: 1rem;
}

.icon-medium svg {
  width: 0.875rem;
  height: 0.875rem;
}

.iconBefore-medium svg {
  width: 0.875rem;
  margin-right: var(--spacing);
}

.iconAfter-medium svg {
  width: 0.5rem;
  margin-right: var(--spacing);
}

.text {
  justify-content: flex-start;

  padding: 0;

  font-weight: var(--regular);
}

.contained {
  justify-content: center;

  padding: 0.75rem 2rem;

  box-sizing: border-box;

  border-radius: 3rem;
}

.contained-medium {
  min-height: 3rem;
}

.outlined {
  justify-content: center;

  padding: 0.75rem 2rem;

  box-sizing: border-box;

  border-width: 1px;
  border-style: solid;
  border-radius: 3rem;
}

.outlined-medium {
  min-height: 3rem;
}

.contained-primary {
  color: rgba(var(--text), 1);

  background-color: rgba(var(--primary-1), 1);

  @include mixin.transition(background-color);

  &:hover,
  &:focus {
    background-color: rgba(var(--primary-1), 0.8);
  }

  &.disabled {
    opacity: 0.4;
  }
}

.outlined-primary {
  color: rgba(var(--primary-1), 1);

  border-color: rgba(var(--primary-1), 1);

  @include mixin.transition(opacity);

  &:hover,
  &:focus {
    opacity: 0.8;
  }
}

.text-primary {
  color: rgba(var(--primary-1), 1);

  @include mixin.transition(color);

  &:hover,
  &:focus {
    color: rgba(var(--primary-1), 0.8);
  }
}

.text-secondary {
  color: rgba(var(--text), 0.5);

  @include mixin.transition(color);

  &:hover,
  &:focus {
    color: rgba(var(--primary-1), 1);
  }
}


