.header {
    position: relative;
    background: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    @media #{$desktop} {
        background-repeat: no-repeat;
        background-size: contain;
        height: 700px;
    }
    @media #{$mdWidth} {
        background: #fff;
        background-repeat: no-repeat;
        background-size: contain;
        height: 700px;
    }
    @media #{$smWidth} {
        background: #fff;
        height: auto;
    }
    @media #{$xsWidth} {
        background: #fff;
        height: auto;
    }
    .center {
        padding: 0;
        @media #{$desktop} {
            width: 1200px;
        }
        @media #{$mdWidth} {
            width: 1200px;
        }
        @media #{$smWidth} {
            width: 100%;
        }
        @media #{$xsWidth} {
            width: 100%;
        }
    }

    @media #{$mdWidth} {
        padding: 0 15px;
        height: auto;

        &__adv {
            display: none;
        }
    }
    @media #{$smWidth} {
        padding: 0 15px;
        height: auto;

        &__adv {
            display: none;
        }
    }
    @media #{$xsWidth} {
        padding: 0 15px;
        height: auto;

        &__adv {
            display: none;
        }
    }
    &__adv {
        h2 {
            margin-top: 140px;
            font-size: 56px;
            color: #22292f;
        }
        & > p {
            margin-top: 20px;
            font-size: 20px;
            color: #7a7f82;
            width: 532px;
        }
        &__img {
            margin-top: 72px;
            display: flex;
            justify-content: flex-start;
            p {
                color: #22292f;
                font-size: 20px;
            }
            img {
                margin-left: 20px;
                width: 133px;
                height: 24px;
            }
        }
    }
    &--bb0 {
        border-bottom-color: transparent;
    }

    &--app .center {
        width: 1440px;
        padding: 0 30px;
    }

    &.menu-show {
        border-bottom: transparent;

        .header__menu {
            display: block;
        }
    }

    &-account {
        display: flex;
        justify-content: inherit;
        align-items: center;
        position: relative;
        color: #000;
        transition: 1s;
        // width: 100%;
        height: 38px;
        padding: 0 16px;
        background: #d4e9e2;
        border-radius: 5px;

        @media #{$mdWidth} {
            width: 153px;
            position: absolute;
            top: 21px;
            right: 65px;
        }

        @media (max-width: 470px) {
            right: unset;
            // left: 20px;
        }

        @media (max-width: 370px) {
            width: 143px;
        }

        .balance {
            width: 160px;
            text-align: right;

            p {
                color: #fff;
                margin-right: 16px;

                @media (max-width: 370px) {
                    margin-right: 8px;
                }
            }
        }

        .address {
            max-width: 153px;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            @media (max-width: 370px) {
                max-width: 120px;
            }

            img {
                width: 24px;
                margin-left: 4px;
            }
        }

        .point {
            width: 16px;
            height: 16px;
            background: var(--yellow);
            border-radius: 50%;
            margin-left: 16px;

            @media (max-width: 370px) {
                display: none;
            }
        }
    }

    &__box {
        display: flex;
        align-items: center;
        height: 100px;
        min-height: 64px;

        @media #{$mdWidth} {
            display: flex;
            height: auto;
            padding: 20px 0;
        }
        @media #{$smWidth} {
            display: flex;
            height: auto;
            padding: 20px 0;
        }
        @media #{$xsWidth} {
            display: flex;
            height: auto;
            padding: 20px 0;
        }
        .more {
            margin-left: 7px;
            display: none;
        }
        @media #{$mdWidth} {
            .more {
                display: block;
            }
        }
        @media #{$smWidth} {
            .more {
                display: block;
            }
        }
        @media #{$xsWidth} {
            .more {
                display: block;
            }
        }
    }

    &__logo {
        position: relative;

        svg {
            width: 155px;
            stroke: var(--color);

            @media #{$mdWidth} {
                width: 155px;
            }
        }

        @media (max-width: 580px) {
            display: none;
        }

        &--small {
            display: none;
            svg {
                width: 155px;
                height: 24px;
            }

            &.active {
                @media (max-width: 470px) {
                    display: block;
                }
            }

            @media (max-width: 580px) {
                display: block;
            }
        }
    }

    &__menu {
        display: flex;
        align-items: center;
        margin-left: 50px;

        @media #{$mdWidth} {
            display: none;
            margin: 20px -20px 0;
            padding-top: 72px;
            border-top: 1px solid rgba(255, 255, 255, 0.1);
        }
        @media #{$smWidth} {
            display: none;
            margin: 20px -20px 0;
            padding-top: 72px;
            border-top: 1px solid rgba(255, 255, 255, 0.1);
        }
        @media #{$xsWidth} {
            display: none;
            margin: 20px -20px 0;
            padding-top: 72px;
            border-top: 1px solid rgba(255, 255, 255, 0.1);
        }
        .active {
            color: #0f8c20;
        }
        &-wrapper {
            display: flex;
            align-items: center;
            margin-left: auto;
            margin-right: 0;
            @media #{$mdWidth} {
                display: block;
            }

            a {
                margin-right: 30px;
                font-size: 14px;
                font-family: HelveticaNeue-Bold, HelveticaNeue;
                font-weight: bold;
                color: #22292f;
            }
        }
    }

    &__btn {
        // margin-left: 24px;
        @media #{$mdWidth} {
            display: block;
            position: absolute;
            top: 9px;
            right: 70px;
        }

        @media (max-width: 370px) {
            position: static;
        }
        @media #{$smWidth} {
            position: static;
        }
        @media #{$xsWidth} {
            position: static;
        }
        @media #{$mdWidth} {
            position: static;
        }
        .connect-btn {
            min-width: 106px;
            height: 38px;
            background: #d4e9e2;
            border-radius: 5px;
            outline: none;
            border: none;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #0f8c20;
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 0 16px;

            img {
                width: 16px;
                height: 16px;
                margin-left: 2px;
            }
        }

        .buttonContainer {
            width: 260px;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            @media #{$mdWidth} {
                width: auto;
            }
        }
    }

    &__lk {
        line-height: 38px;
        white-space: nowrap;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 20px;
        display: flex;

        &-balance {
            padding: 0 12px;
            color: var(--color);
        }

        &-number {
            padding: 0 50px 0 18px;
            position: relative;
            background-color: #fff;
            color: var(--bg);
            border-radius: 20px;
            margin-left: 18px;
            font-size: 14px;

            &::after {
                position: absolute;
                width: 16px;
                height: 16px;
                top: 0;
                right: 16px;
                bottom: 0;
                margin: auto;
                content: '';
                border-radius: 50%;
                background-color: var(--yellow);
            }
        }
    }
}

@media #{$mdWidth} {
    .btn-menu-toggle {
        width: 48px;
        height: 48px;
        position: absolute;
        top: 10px;
        right: 8px;

        &::after,
        &::before {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 24px;
            height: 2px;
            transition: var(--transitionDefault);
            background-color: var(--color);
            content: '';
        }

        &::before {
            transform: rotate(180deg) translate3d(0, 5px, 0);
        }

        &::after {
            transform: rotate(180deg) translate3d(0, -5px, 0);
        }

        .menu-show & {
            &::before {
                transform: rotate(45deg) translate3d(0, 0px, 0);
            }

            &::after {
                transform: rotate(-45deg) translate3d(0, 0px, 0);
            }
        }
    }

    .menu-show {
        .main {
            display: none;
        }

        .header {
            flex-grow: 1;
            border-bottom: 0;
        }

        .header__menu {
            display: block !important;
        }

        .footer {
            background-color: transparent;
        }
    }
}
@media #{$smWidth} {
    .btn-menu-toggle {
        display: none;
    }
}
@media #{$xsWidth} {
    .btn-menu-toggle {
        display: none;
    }
}

.mobile_menu {
    .MuiDrawer-paperAnchorBottom {
        top: 80px;
    }
    .MuiDrawer-paper {
        background-color: #000;
        border-top: 1px solid rgba(255,255,255, .2);
        color: #fff;
        padding-left: 8px;
        a {
            text-decoration: none;
            display: block;
            width: 100%;
        }
        .active {
            span {
                border-bottom: 2px solid #aaa;
            }
        }
        .MuiList-padding {
            padding-top: 5vh;
        }
        .MuiTypography-body1 {
            font-size: 24px;
            line-height: 36px;
            margin: 5px 0;
            display: inline-flex;
            align-items: center;
        }
        .menu_children {
            padding-left: 20px;
            .MuiListItem-button {
                padding: 0 16px;
            }
            .MuiTypography-body1 {
                margin: 0;
                font-size: 16px;
            }
        }
    }
}