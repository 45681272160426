@use "../../helpers/style/mixin";
@use "../../helpers/style/media";

.component {
  --max-width: 53.75rem;

  margin-top: calc(var(--spacing) * 4);
}

.wrapper {
  position: relative;
}

.wrapper::before {
  --height: 8.25rem;

  position: absolute;
  bottom: calc(var(--height) / -2);
  left: 50%;
  content: '';

  display: block;
  width: calc(100% - var(--gutters) * 2);
  max-width: var(--max-width);
  height: var(--height);

  border-radius: 50%;

  background-color: rgba(var(--white), 0.05);
  filter: blur(100px);

  transform: translateX(-50%);
}

.content {
  position: relative;

  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;

  background: radial-gradient(
    298.23% 61.33% at 48.26% -5.97%,
    #9e00ff 0%,
    #9e00ff 6.18%,
    #171643 17.74%,
    #131315 29.84%,
    #131315 100%
  );

  border-radius: 2rem;

  box-shadow: inset 0 2px 2px rgba(var(--white), 0.25);
}

.header {
  position: relative;

  display: grid;
  grid-template-areas: 'title wallet' 'navigation navigation';
  justify-content: space-between;

  padding: calc(var(--spacing) * 3) calc(var(--spacing) * 4) 0;
}

.header::before {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';

  display: block;
  height: 1px;
  background-color: rgba(var(--white), 0.1);
}

.title {
  grid-area: title;
  margin: 0;
}

.list {
  grid-area: navigation;

  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  grid-gap: calc(var(--spacing) * 4.5);

  margin: 0;
  margin-top: calc(var(--spacing) * 3);
  padding: 0;

  list-style: none;
}

.link {
  position: relative;

  display: inline-block;

  padding-bottom: var(--spacing);

  font-size: var(--body-2);
  line-height: 1.2;
  color: rgba(var(--text), 0.6);
  text-decoration: none;

  @include mixin.transition(color);
}

.link::after {
  position: absolute;
  bottom: 0;
  left: 50%;
  content: '';

  width: 100%;
  height: 2px;

  background-color: rgba(var(--primary-1), 1);

  transform: translateX(-50%) scaleX(0);
  transform-origin: center;

  @include mixin.transition(transform);
}

.link:hover,
.link:focus,
.active {
  color: rgba(var(--primary-1), 1);
}

.link:hover::after,
.link:focus::after,
.active::after {
  transform: translateX(-50%) scaleX(1);
}

.timer {
  grid-area: timer;

  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 10rem;
  height: 2rem;

  border: 1px solid rgba(var(--primary-1), 1);
  border-radius: 3rem;
}

.wallet {
  grid-area: wallet;

  display: grid;
  grid-auto-flow: column;
  grid-column-gap: var(--spacing);
  align-items: center;
  cursor: pointer;
  position: relative;
}
.timer_button {
  position: absolute;
  top: 120%;
  right: 0;
  max-width: 188px;
  height: 30px !important;
  min-height: unset !important;
  color: #9867ff !important;
  background-color: transparent !important;
  border: 1px solid #9867ff !important;
}

.main {
  position: relative;
  padding: calc(var(--spacing) * 4.5) calc(var(--spacing) * 4);
}
