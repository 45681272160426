@import './mixin.scss';
@import './_variables.scss';

#header_main {
  width: 100%;
  margin: 0;
  padding: 20px 60px 20px 40px;
  height: $headerHeight;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #1C1C1F;
  @media #{$mobile} {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 20px 25px;
    height: 83px;
    bottom: 0;
    top: unset;
    width: 100vw;
    overflow: hidden;
    background-color: #171717;
    border-top: 1px solid rgba(255, 255, 255, 0.2);

    img {
      width: 136px;
      height: 32px;
      object-fit: contain;
    }
  }
  .header_constant_wrapper {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    grid-gap: 60px;
  }
  .nav_link_wrapper {
    display: flex;
    justify-content: center;
    @include breakpoint($md) {
      padding: 1rem 0 1rem 1rem;
      justify-content: flex-end;
    }
  }
  .nav_link {
    display: flex;
    flex-flow: row nowrap;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    color: $text3;
    width: fit-content;
    margin: 0 12px;
    font-weight: 400;
    font-size: 0.875rem;
    white-space: nowrap;
    &:hover,
    :focus {
      color: $text1 !important;
    }
    &.active {
      border-radius: 12px;
      font-weight: 600;
      color: $text1;
    }
    &.parent {
      position: relative;
      display: flex;
      align-items: center;
      .nav_link_child {
        position: absolute;
        top: calc(100% + 10px);
        left: 0;
        height: 0;
        transition: 0.5s;
        overflow: hidden;
        width: 165px;
        background: #0f0f10;
        border-radius: 14px;
        .nav_link {
          width: 100%;
          padding: 14px;
          margin: 0;
          &:hover {
            color: $primary1 !important;
          }
          &.border-top {
            border-top: 1px solid hsla(0, 0%, 100%, 0.2);
          }
        }
      }
      &:hover {
        .nav_link_child {
          height: auto;
          border: 1px solid hsla(0, 0%, 100%, 0.2);
          box-shadow: 0 2px 14px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
  .connect_wallet_button {
    background-color: $primary1;
    border-color: $primary1;
    transition: 0.5s;
    &:hover {
      color: $primary1;
      background-color: transparent;
    }
  }
  .token_button {
    font-size: 14px;
    background: linear-gradient(96.37deg, #620aab 18.99%, #221453 92.88%);
    border-radius: 100px;
    padding: 0 27px;
    text-decoration: none;
    height: 36px;
    line-height: 36px;
    &:hover {
      background: linear-gradient(96.37deg, #7433FF 18.99%, #221453 92.88%);
    }
    @media #{$smWidth} {
      padding: 0 10px;
      white-space:nowrap;
    }
  }
}
.modal_overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  z-index: 100;
  top: $headerHeight;
  left: 0;
  height: calc(100vh - #{$headerHeight});
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  @media #{$mobile} {
    height: 100%;
    top: 0;
  }
  button {
    img {
      width: 20px;
      height: 20px;
    }
  }
}

.connected_chain {
  margin-right: 12px;
  margin-left: 19px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  height: 40px;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.12);
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  @media #{$smWidth} {
    margin-right: 10px;
  }
  img {
    width: 16px!important;
  }

  p {
    font-family: Roboto-Regular;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    margin: 0 12px;
  }
  &:hover {
    .children {
      height: auto;
      border: 1px solid hsla(0, 0%, 100%, 0.2);
      box-shadow: 0 2px 14px rgba(0, 0, 0, 0.2);
    }
  }
  .filler {
    position: absolute;
    top: 100%;
    width: 80px;
    height: 10px;
  }
  .children {
    position: absolute;
    z-index: 999;
    top: calc(100% + 10px);
    left: 0;
    height: 0;
    transition: 0.5s;
    overflow: hidden;
    width: 180px;
    background: #0f0f10;
    border-radius: 14px;
    @media #{$smWidth} {
      position: fixed;
      z-index: 999;
      left: 26%;
      top: auto;
      bottom: 70px;
    }
    button {
      border: none;
      justify-content: flex-start;
      padding: 8px 14px;
      &:hover {
        background-color: rgba(255, 255, 255, 0.05);
      }
    }
  }
}
