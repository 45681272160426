@import './mixin.scss';
@import './_variables.scss';

.popup_column {
  position: fixed;
  top: 140px;
  right: 4rem;
  max-width: 355px !important;
  z-index: 3;

  @media #{$mobile} {
    top: 0;
    right: 0;
  }
}

.mobile {
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Futura PT;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 18px;
  line-height: 148.69%;
  letter-spacing: 0.01em;
  padding: 40px;
  text-align: center;
  display: none;

  @media screen and (min-width: 420px) {
    display: none;
  }
}

.page {
  position: relative;

  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  width: 100%;
  height: 100%;

  @media #{$mobile} {
    display: flex;
    // height: 100vh;
    // max-height: 100vh;
    flex-grow: 1;
    justify-content: flex-start;
    // min-height: unset;
    min-height: calc(100vh - 90px);

    &__main {
      max-height: unset;
    }
  }

  &__main {
    // flex-grow: 1;
    // display: flex;
    // flex-direction: column;

    padding: 0;
    padding-top: calc(5vh + 88px);

    @media #{$mobile} {
      padding: 0;
    }
  }

  .mobile_filler {
    height: $headerHeight;
  }

  .logo_header {
    display: none;
    img {
      width: 150px;
    }
    @media #{$mobile} {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 90px;
      padding: 24px 28px;
    }
  }
  header {
    display: flex;
    justify-content: space-between;
    margin: 45px 60px 0 43px;
    align-items: center;
    font-family: 'Roboto';

    .logo_header_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: fit-content;
      @media #{$mobile} {
        flex: 1;
      }
    }
    .menu_link {
      width: 98px;
      min-width: 98px;
      height: 32px;
      text-decoration: none;
      border: 1px solid #ffffff;
      border-radius: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 28px;
      svg {
        width: 12px;
        height: 12px;
        margin-right: 8px;
      }
    }

    .chain_info {
      display: flex;
      align-items: center;
      font-family: Roboto-Regular;
      font-style: normal;
      font-weight: 500;
      @media #{$mobile} {
        width: 100%;
        justify-content: center;
      }
    }

    @media #{$mobile} {
      margin: 0;
      padding: 22px 25px;
      position: fixed;
      bottom: 0;
      width: 100vw;
      overflow: hidden;
      z-index: 2;
      background-color: #171717;
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      .logo {
        display: none;
      }

      img {
        width: 136px;
        height: 32px;
        object-fit: contain;
      }
    }
    .claim_modal {
      img {
        width: inherit;
      }
    }

    .wallet {
      border: 1px solid #ffffff;
      box-sizing: border-box;
      border-radius: 4px;
      height: 40px;
      padding: 0 16px;
      display: flex;
      align-items: center;

      @media #{$mobile} {
        padding: 0 12px;
        line-height: 18px;
      }

      &__balance {
        padding-right: 16px;
        border-right: 1px #ffffff solid;
        font-size: 13px;
        @media #{$mobile} {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding-right: 10px;
        }
      }

      &__address {
        padding-left: 16px;
        color: rgba(255, 255, 255, 0.6);
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 12px;

        &:hover {
          opacity: 0.9;
        }

        @media #{$mobile} {
          padding-left: 10px;
        }

        .dot {
          width: 12px;
          height: 12px;
          background: linear-gradient(
            135deg,
            #ffffff 4.17%,
            rgba(255, 255, 255, 0) 75%
          );
          border: 0.6px solid #ffffff;
          box-sizing: border-box;
          border-radius: 6px;
          margin-right: 6px;
          @media #{$mobile} {
            // margin-right: 0;
          }
        }

        svg {
          width: 16px;
          margin-left: 6px;
          cursor: pointer;

          @media #{$mobile} {
            display: none;
          }

          &:hover {
            g {
              opacity: 1;
            }
          }
        }
      }
    }

    .buttons {
      & > * {
        margin-left: -8px;
        margin-right: -8px;
      }
      button {
        width: fit-content;
        height: 32px;
        margin-left: 8px;
        margin-right: 8px;
        @media #{$mobile} {
          width: 100%;
          height: 40px;
          line-height: 40px;
          border-radius: 49px;
        }
      }
      .connect_wallet_button {
        @media #{$mobile} {
          width: 100%;
          height: 40px;
          line-height: 40px;
        }
      }
    }
  }
}

.bridge {
  width: 560px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0) 100%), #000000;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 20px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 19px 39px 36px;
  position: relative;
  overflow: hidden;

  .success-notice {
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.4);
    box-sizing: border-box;
    border-radius: 10px;
    height: 48px;
    font-size: 16px;
    padding: 12px 20px;
    display: flex;
    align-self: center;
    justify-content: space-between;
    margin-top: 20px;
    >.check-box {
      border: 1px solid #24FF00;
      box-sizing: border-box;
      width: 24px;
      height: 24px;
      color: #24FF00;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      >svg {
        width: 12px;
      }
    }
  }

  @media #{$mobile} {
    // flex: 1;
    width: $mobileCenterWidth;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    justify-content: flex-start;
    // min-height: 100%;
    padding-bottom: 160px;
    padding: 20px;
    margin: 0 16px;
    border-radius: 20px;
    margin-bottom: 30px;
  }

  .inputs {
    position: relative;
    width: 100%;

    @media #{$mobile} {
      width: 100%;
    }

    & > * {
      margin: 20px 0;
      @media #{$mobile} {
        margin: 24px 0;
      }
    }
  }

  &__claim_text {
    color: #ffffff;
    text-decoration: underline;
    margin-top: 24px;
  }

  &__title {
    font-family: Futura PT;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    align-self: flex-start;
    margin-bottom: 20px;
    line-height: 42px;
    letter-spacing: 0.01em;

    @media #{$mobile} {
      font-size: 26px;
      margin-top: 0px;
    }
  }

  &__dropdown_frame {
    display: flex;

    img {
      padding: 0 16px;
      padding-top: 24px;
    }

    @media screen and (min-width: 747px) {
      .arrow_down-box {
        position: relative;
        width: 18px;
        height: 0;
        z-index: 2;
        .arrow_down {
          width: 32px;
          height: 32px;
          padding: 7px;
          top: 34px;
          left: -7.5px;
          border: 1px solid rgba(255, 255, 255, 0.4);
          border-radius: 50%;
          background-color: #000;
          cursor: pointer;
          &:hover {
            border-color: #7433ff;
          }
        }
      }
    }

    @media #{$mobile} {
      flex-direction: column;

      .arrow_down-box {
        height: 32px;
        margin-top: -6px;
      }

      .arrow_down {
        width: 32px;
        height: 32px;
        padding: unset;
        margin: 12px auto auto;
        -ms-transform: rotate(90deg); /* IE 9 */
        -webkit-transform: rotate(90deg); /* Safari and Chrome */
        transform: rotate(90deg);
        padding: 6px;
        border: 1px solid rgba(255, 255, 255, 0.4);
        border-radius: 50%;
      }
    }

    .dropdown {
      width: calc( 50% - 10px );
      height: 80px;

      .default_drop {
        width: 176px;
        height: 46px;
        background-color: #1f1f1f;
        box-sizing: border-box;
        border-radius: 14px;
        display: flex;
        align-items: center;

        @media #{$mobile} {
          width: auto;
        }

        img {
          height: 24px;
          margin-left: 20px;
          padding: 0;

          @media #{$mobile} {
            margin-right: 0;
          }
        }

        p {
          font-family: Roboto-Regular;
          font-weight: 500;
          font-size: 16px;
          margin-left: 20px;
        }
      }

      span {
        font-family: Roboto-Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 148.69%;
        color: rgba(255, 255, 255, 0.6);
      }

      div {
        height: 8px;
      }

      @media #{$mobile} {
        width: 100%;
      }
    }

    .dropdown:hover {
      z-index: 1;
    }
  }

  &__input_frame {
    display: flex;
    flex-direction: column;
    margin-top: -4px !important;
    width: 100%;
    @media #{$mobile} {
      width: 100%;
    }

    p {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
      display: flex;
      line-height: 148.69%;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 12px;
      white-space: nowrap;
    }

    input {
      width: -webkit-fill-available;
      height: 100%;
      outline: none;
      border: 1px solid transparent;
      color: #ffffff;
      background-color: transparent;
      padding: 0 20px;
      font-size: 16px;
      font-family: Roboto-Regular;

      //&:hover,
      //&:focus {
      //  border: 1px solid #9867ff;
      //  border-radius: 14px;
      //}

      &:disabled {
        border: unset;
      }

      @media #{$mobile} {
        padding-right: 20px;
      }
    }

    .error {
      color: rgba(255, 0, 0, 1);
      font-size: 13px;
      height: 0;
      margin: 0;
    }

    .max {
      margin-top: 0px !important;
      right: 12px;
      top: 10px;
      width: fit-content;
      height: 28px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      line-height: 26px;
      padding: 0 20px;
      background-color: transparent;
      width: fit-content;
      position: absolute;
      margin-left: 150px;
      border: 1px solid #ffffff;
      border-radius: 45px;
      &:hover {
        border-color: #7433ff;
      }
      @media #{$mobile} {
        display: none;
        padding: 0 10px;
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        top: 12px;
      }
    }

    &__extra {
      border: 1px solid transparent;
      display: flex;
      align-items: center;
      height: 48px;
      background: rgba(255, 255, 255, 0.12);
      border-radius: 10px;
      box-sizing: border-box;
      position: relative;
      @media #{$mobile} {
        justify-content: space-between;
        input {
          width: 100%;
        }
      }
      p {
        margin-left: 12px;
        margin-right: 20px;
        width: auto;
        font-size: 16px;
      }

      img {
        width: 24px;
        height: 24px;
        z-index: 0;
        margin-left: 20px;
      }

      &:hover {
        border: 1px solid #7433ff;
      }

      &:focus {
        border: 1px solid #7433ff;
      }
      input {
        &:disabled {
          opacity: .6;
        }
      }
      .main_content {
        width: 62.5%;
      }

      .asset {
        // width: 37.5%;
        width: 100%;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: space-between;
        border-radius: 10px;
        padding: 14px 20px;
        box-sizing: border-box;
        @media #{$mobile} {
          padding: 14px 5px;
        }
        &.asset_selected {
          background-color: #333;
        }
        p{
          font-family: Roboto-Regular;
          font-weight: 500;
          color: #FFFFFF;
          margin: 0;
        }
        @media #{$mobile} {
          p {
            margin: 0;
          }
        }
        .token-tit {
          display: flex;
          >img {
            margin-right: 10px;
            @media #{$mobile} {
            }
          }
        }
        img {
          width: 12px;
          // margin-right: 12px;
          margin-left: 0;
          @media #{$mobile} {
            // margin-left: 10px;
            margin-right: 10px;
          }
        }
        &:hover {
          opacity: 0.8;
          // border-radius: 14px;
          background-color: rgba(255, 255, 255, 0.08);
          cursor: pointer;
        }
        .icon {
          width: auto;
          margin-right: 0;
        }
      }
      .asset_default {
        background-color: $primary1;
        &:hover,
        :focus {
          &:not([disabled]) {
            color: #ffffff;
            background-color: #7433ff;
          }
        }
      }
    }

    &__claim_list {
      background: transparent;
      &:hover {
        border: 1px solid transparent;
      }
    }
  }
  .claim_list_button {
    // position: absolute;
    border-radius: 0;
    border: none;
    // border-top: 1px solid rgba(255, 255, 255, 0.2);
    bottom: 0;
    height: 60px;
    margin-top: 15px;
    @media #{$mobile} {
      border: none;
      text-decoration: underline;
      margin-top: 8px;
      background-color: transparent;
      height: 48px;
    }
  }
  button {
    @media #{$mdHeight} {
      height: 48px;
      font-size: 16px;
    }

    img {
      margin-right: 12px;
      width: 20px;
      height: 20px;
      margin-bottom: 0;
    }
  }
}

.extra {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  height: 48px;
  // background: rgba(255, 255, 255, 0.12);
  background: #262626;
  border-radius: 10px;
  margin-bottom: 17px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 416px;
  padding: 0 40px;

  &__header {
    width: 100%;
    margin: 12px 0 !important;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    color: rgba(255, 255, 255, 0.8);

    a {
      color: rgba(152, 103, 255, 1);
      text-decoration: none;
    }
  }

  @media #{$mobile} {
    height: fit-content;
    padding: 14px 32px 20px;
  }

  &__chain {
    display: flex;
    align-items: center;

    @media #{$mobile} {
      flex-direction: column;
      margin-top: -4px;
      margin-bottom: -4px;
      & > * {
        margin-top: 4px;
        margin-bottom: 4px;
      }
    }
  }

  p {
    @media #{$mobile} {
      font-size: 18px;
    }
  }

  img {
    height: 28px;
    @media #{$mobile} {
      width: 32px;
    }
  }

  .arrow {
    flex: 1;
    height: 12px;
  }

  h5 {
    font-size: 16px;
    font-family: Roboto-Regular;
    color: #ffffff;
  }

  @media #{$mobile} {
    width: initial;
  }

  .amount {
    flex: 1;
    text-align: center;
  }
}

.gray_frame {
  border-radius: 22px;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  height: auto;

  & > * {
    margin-top: -8px;
    margin-bottom: -8px;
  }

  ul {
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
    width: 100%;
    justify-content: space-between;

    li {
      max-width: 50%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: flex;
      align-items: center;
    }
  }
}
.claim_disabled {
  opacity: 0.6;
  cursor: initial;
}

.claim {
  &__amount {
    font-family: Futura PT;
    font-style: normal;
    font-weight: 500;
    font-size: 40px !important;
    line-height: 133.5%;
  }
}

.submitted_mode {
  z-index: 99;
  padding: 40px;
  @media #{$noMobile} {
    width: 440px !important;
  }
  @media #{$mobile} {
    padding: 20px;
    // flex: 1;
    // margin-bottom: 0;
    // width: -webkit-fill-available;
  }

  > img {
    width: 40px;
    height: 40px;

    @media #{$mobile} {
      width: 32px;
      height: 32px;
      margin: auto;
      margin-bottom: 20px;
      margin-top: 40px;
    }
  }

  > p {
    font-size: 18px;
    @media #{$mobile} {
      font-family: Roboto-Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 24px !important;
      line-height: 124%;
      width: 259px;
    }
  }

  a {
    margin-top: 20px;
    font-family: Roboto-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 148.69%;
    text-align: center;
    text-decoration-line: underline;
    color: #ffffff;
    &:hover {
      color: #9867ff;
    }
    @media #{$mobile} {
      font-size: 16px;
      margin-top: 36px;
    }
  }

  .add_token {
    width: 210px;
    height: 36px;
    font-family: Roboto-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 100px;
    cursor: pointer;
    @media #{$mobile} {
      width: 266px;
      height: 36px;
      margin-top: 20px;
      p {
        font-size: 16px;
      }
    }

    img {
      width: 15.88px;
      margin-left: 8px;
    }
  }

  button {
    margin-top: 32px;

    @media #{$mobile} {
      // margin-top: auto;
      // margin-bottom: 100px;
      width: 100%;
      height: 48px;
      font-size: 18px;
    }
  }
}

.claimed_mode {
  @media #{$mobile} {
    flex: 0;
    margin-bottom: 0;
    width: -webkit-fill-available;
  }

  > img {
    width: 32px;
    height: 32px;

    @media #{$mobile} {
      width: 60px;
      height: 60px;
    }
  }

  > p {
    font-size: 18px;
    @media #{$mobile} {
      font-family: Roboto-Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 24px !important;
      line-height: 124%;
      width: 259px;
    }
  }

  a {
    margin-top: 32px;
    font-family: Roboto-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 148.69%;
    text-align: center;
    text-decoration-line: underline;
    color: #ffffff;
    &:hover {
      border: 1px solid #9867ff;
      color: #9867ff;
    }
    @media #{$mobile} {
      font-size: 16px;
      margin-top: 36px;
    }
  }

  .add_token {
    width: 210px;
    height: 36px;
    font-family: Roboto-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 100px;
    cursor: pointer;
    @media #{$mobile} {
      width: 266px;
      height: 36px;
      margin-top: 20px;
      p {
        font-size: 16px;
      }
    }

    img {
      width: 15.88px;
      margin-left: 8px;
    }
  }

  button {
    @media #{$mobile} {
      margin-top: 70px !important;
      height: 60px;
      font-size: 18px;
    }
  }
}

.claim_modal {
  @media #{$noMobile} {
    min-height: 510px;
  }
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #000000;
  border: 1px solid rgba(255, 255, 255, 0.2);

  nav {
    width: 128px;
    border-radius: 4px;
    max-height: 32px;

    &::after {
      line-height: 32px;
      right: 12px;
      font-size: 12px;
    }

    li > a {
      font-size: 12px;
      line-height: 32px;
      height: 32px;
      padding-left: 12px !important;
    }
  }
  @media #{$mobile} {
    // flex: 0;
    // margin-bottom: 0;
    // padding: 48px 24px;
    // width: -webkit-fill-available;
    // max-height: unset;

    .claim__amount {
      margin-bottom: 20px;
      margin-top: 20px;
      font-size: 32px !important;
      @media #{$mobile} {
        margin-bottom: 10px;
        margin-top: 10px;
      }
    }
  }
  .default_modal__header {
    @media #{$mobile} {
      display: flex;
      flex-direction: column;
      padding: 0;
    }
  }
}

.claim_modal {
  // z-index: 8;
  padding: 20px 40px;
  border-radius: 20px;
  @media #{$mobile} {
    padding: 20px;
    // z-index: 9;
    // width: 100% !important;
    // height: fit-content;
    // position: absolute;
    // top: 90px;
    // left: 0;
    // box-sizing: border-box;
    // justify-content: flex-start;
    // padding: 20px;
    // padding-top: 20px;
    // padding-bottom: 100px;
  }

  .default_modal__title {
    font-size: 16px;display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    margin: 0 auto;
  }
  .extra {
    width: 100%;
  }
  .claim__token {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    font-size: 16px;
    line-height: 133.5%;
    >img {
      margin-right: 8px;
      width: 24px;
      height: auto;
    }
  }
  .chain_tip {
    justify-content: center;
    color: #fff;
    font-size: 14px;
    &>p:first-child {
      opacity: 0.4;
      display: inline-block;
      margin-right: 8px;
    }
    &>p:last-child {
      opacity: 0.6;
    }
  }

  .claim_list {

    overflow: auto;
    height: 370px;
    @media #{$mobile} {
      min-width: 100%;
      position: relative;
      height: 100%;
      padding-bottom: 60px;
      // margin-top: 90px;
    }

    .loading_frame {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      @media #{$mobile} {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }

    .empty_list {
      margin: auto;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
    }
    &_header {
      button {
        font-size: 12px;
        height: 32px;
        padding: 8px;
      }
      .button_select {
        & > button {
          border-radius: 4px;
          border: 1px solid rgba(255, 255, 255, 0.6);
          @media #{$mobile} {
            border-radius: 14px;
            border: 1px solid transparent;
            margin-top: 20px;
          }
        }
      }
      @media #{$mobile} {
        padding: 0;
        width: 100%;
        button {
          height: 48px;
          font-size: 16px;
        }
      }
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.2);
    margin: 28px 0;
  }

  &__footer {
    font-family: Roboto;
    font-size: 14px;
    display: flex;
    width: 398px;
    justify-content: space-between;
    margin-top: 20px;
  }
}

.deposit_confirm_modal {
  z-index: 99;
  @media #{$noMobile} {
    width: 480px;
  }

  .extra_frame {
    @media #{$mobile} {
      width: 100%;
    }
  }
  &_amount {
    font-size: 40px;
    text-align: center;
    padding: 10px 30px 14px;
    white-space:nowrap;
    text-overflow:ellipsis;
    overflow: hidden;
    max-width: 416px;
  }
  &_token {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    max-width: 416px;
    margin-bottom: 24px;
    >img {
      height: 20px;
      margin-right: 6px;
    }
  }
  &_notice {
    font-size: 14px;
    text-align: left !important;
    justify-self: start;
    margin-top: -6px;
    opacity: .6;
    word-break: break-all;
  }
  &_noticep {
    opacity: 0.5;
    margin-top: 12px;
    font-size: 12px
  }
}

.switch_btn {
  width: 428px !important;
  height: 48px !important;
  margin-top: 16px;

  &:hover {
    border: 1px solid #9867ff;
    color: #9867ff;
  }

  @media #{$mobile} {
    width: 100% !important;
  }
}

.quota_frame {
  // margin: 20px -32px 0;
  border-top: 1px solid $text5;
  width: 480px;
  display: grid;
  grid-template-columns: 1;
  grid-gap: 16px;
  padding-top: 20px;
  margin: 0 -32px -36px -32px;
  width: calc(100% + 76px);
  >.row_between {
    padding: 0px 40px;
    @media #{$mobile} {
      padding: 0px 20px;
    }
  }

  @media #{$mobile} {
    width: calc(100% + 40px);
    margin-top: 20px;
    margin-bottom: -20px;
  }
  .progress_frame {
    width: 100%;
    height: 4px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    .progress {
      background: #9867ff;
      border-radius: 5px;
      height: 100%;
    }
  }
}

.claim_item {
  background: rgba(255, 255, 255, 0.08);
  border-radius: 10px;
  height: 64px;
  padding: 9px 20px;
  display: flex;
  width: 100%;
  margin-bottom: 12px;
  @media #{$mobile} {
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    height: auto;
    & > img {
      width: 12px;
      margin-top: 16px;
    }
  }
  .divider {
    width: 100%;
    margin: 8px 0;
  }

  > svg {
    fill: transparent;
    margin: auto 18px;
  }

  > button {
    padding: 6px 14px;
    box-sizing: border-box;
    border-radius: 100px;
    height: 36px;
    width: 62px;
    margin: auto;
    @media #{$mobile} {
      padding: 0 14px;
      width: 80px;
      font-size: 13px;
    }
  }

  &__item {
    width: fit-content;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 12px;

    &__loading {
      width: 20px;
      height: 20px;
      -webkit-animation: loading 3s linear infinite;
      margin: auto;
      margin-right: 0;
    }
    @media #{$mobile} {
      margin-right: 0;
      &.amount {
        p {
          display: flex;
        }
      }
    }

    > p {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 148.69%;
      color: rgba(255, 255, 255, 0.6);
      text-align: left;
    }

    > div {
      display: flex;
      align-items: center;
      font-size: 14px;
      margin-top: 5px;
      color: #ffffff;

      img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }

      p {
        font-size: 14px;
        margin-right: 12px;
      }

      .arrow {
        width: 12px;
      }
    }
  }
}

footer {
  position: fixed;
  bottom: 28px;
  left: 45px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media #{$mobile} {
    display: none;
  }

  a:hover {
    text-decoration: underline;
    opacity: 1;
    color: #9867ff;
  }

  ul {
    display: flex;
    gap: 48px;
    margin-right: 90px;
    align-items: center;

    li {
      @media #{$mobile} {
        flex: 1;
        display: flex;
        justify-content: center;
      }
      a {
        svg {
          fill: #7a7f82;
        }
        &:hover svg {
          fill: #ffffff;
        }
      }
    }
  }
}

.step_frame {
  display: flex;
  align-items: center;
  margin: 20px 0;

  & > * {
    margin: 0 0px;
  }

  .un_active {
    opacity: 0.4;
  }

  @media #{$mobile} {
    margin: 12px;
  }

  div {
    width: 184px;
    height: 1px;
    background: linear-gradient(
      90deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0.4) 100%
    );
    opacity: 0.6;
    @media #{$mobile} {
      width: 60px;
    }
    &.check {
      background: linear-gradient(90deg, #24ff00 0%, #ffffff 100%);
    }
  }

  .step_circle {
    border: 1px solid #24ff00;
    border-radius: 100px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: unset;

    @media #{$mobile} {
      width: 16px;
      height: 16px;
    }
  }

  svg {
    color: #24ff00;
  }

  p {
    width: 23px;
    height: 23px;
    border-radius: 11.5px;
    border: 1px solid #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    @media #{$mobile} {
      width: 16px;
      height: 16px;
    }
  }
}

.main_content {
  position: relative;
  flex: 1;
  height: 48px;
  transition: all .8s;
  // input {
  //   &:hover,
  //   &:focus {
  //     border: 1px solid #9867ff;
  //     border-radius: 14px;
  //   }
  // }
}

.asset_disabled {
  opacity: 0.6;
  color: rgba(255, 255, 255, 0.6);
  background-color: $primary1;
  &:hover {
    opacity: 0.6!important;
    color: rgba(255, 255, 255, 0.6)!important;
    background-color: $primary1!important;
  }
}
.disabled {
  opacity: 0.6;
  color: rgba(255, 255, 255, 0.6);
}
