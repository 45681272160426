.defaultTitle {
  margin: 0;

  text-align: center;
}

.defaultList {
  position: relative;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: calc(var(--spacing) * 5);

  margin: 0;
  margin-top: calc(var(--spacing) * 3.5);
  padding: 0;

  list-style: none;
}

.defaultList::before {
  position: absolute;
  left: 50%;
  bottom: calc(var(--spacing) * 2.125);
  content: "";

  display: block;
  width: 1.25rem;
  height: 0.875rem;

  background-image: url("./assets/arrow.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;

  transform: translateX(-50%);
}

.defaultItem {
  display: grid;
  grid-row-gap: calc(var(--spacing) * 0.5);
}

.defaultAmount {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  min-height: 3rem;
  padding: calc(var(--spacing) * 1.5) calc(var(--spacing) * 2);

  box-sizing: border-box;

  background: rgba(var(--white), 0.08);
  border-radius: 0.875rem;
}

.token,
.eth,
.usdt {
  display: flex;
  align-items: center;
}

.eth::before {
  position: relative;
  content: "";

  display: block;
  width: 1rem;
  height: 1.5rem;
  margin-right: var(--spacing);

  background-image: url("./assets/eth.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}

.usdt::before {
  position: relative;
  content: "";

  display: block;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: var(--spacing);

  background-image: url("./assets/usdt.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}

.token::before {
  position: relative;
  content: "";

  display: block;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: var(--spacing);

  background-image: url("./assets/chainswap.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.defaultButton {
  margin-top: calc(var(--spacing) * 5);
}

.loading {
  display: grid;
  align-content: center;
  justify-items: center;

  height: 100%;
}

.loadingTitle {
  margin: 0;
  margin-top: calc(var(--spacing) * 3.5);
}

.loadingText {
  margin: 0;
  margin-top: calc(var(--spacing) * 0.5);
}

.loadingNote {
  margin: 0;
  margin-top: calc(var(--spacing) * 3);
}

.claimed {
  display: grid;
  align-content: center;

  height: 100%;
}

.claimedTitle {
  margin: 0;

  text-align: center;
}

.claimedTitle::before {
  position: relative;
  content: "";

  display: block;
  width: 2rem;
  height: 2rem;
  margin: 0 auto;
  margin-top: calc(var(--spacing) * 3.5);
  margin-bottom: calc(var(--spacing) * 1.5);

  background-image: url("./assets/success.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}

.claimedText {
  margin: 0;
  margin-top: calc(var(--spacing) * 0.5);

  text-align: center;
}

.claimedLink {
  margin: 0 auto;
  margin-top: calc(var(--spacing) * 2);
}

.claimedButton {
  margin: 0;
  margin-top: calc(var(--spacing) * 3);
}

.failed {
  display: grid;
  align-content: center;

  height: 100%;
}

.failedTitle {
  margin: 0;

  text-align: center;
}

.failedTitle::before {
  position: relative;
  content: "";

  display: block;
  width: 2rem;
  height: 2rem;
  margin: 0 auto;
  margin-bottom: calc(var(--spacing) * 2.5);

  background-image: url("./assets/error.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.failedText {
  margin: 0;
  margin-top: calc(var(--spacing) * 0.5);

  text-align: center;
}

.failedButtons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: var(--spacing);

  margin: 0;
  margin-top: calc(var(--spacing) * 3);
}
