@media screen and (min-width: 750px) {
    .noConnect {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
            width: 371px;
            height: 210px;
        }
        p {
            width: 100%;
            text-align: center;
            height: 17px;
            font-size: 12px;
            color: #7a7f82;
            line-height: 17px;
        }
    }
}
@media screen and (max-width: 750px) {
    .noConnect {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
            width: 371px;
            height: 210px;
        }
        p {
            width: 100%;
            text-align: center;
            height: 17px;
            font-size: 12px;
            color: #7a7f82;
            line-height: 17px;
        }
    }
}
