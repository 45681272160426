@use "../../helpers/style/mixin";
@use "../../helpers/style/media";

.component {
}

.label {
  display: grid;
  grid-row-gap: 0.5rem;

  font-weight: var(--medium);
  color: rgba(var(--text), 0.6);
  position: relative;
}

.input {
  display: inline-block;

  min-height: 3rem;
  padding: 0.5rem 1.25rem;

  box-sizing: border-box;

  font-family: var(--primary-font);
  font-size: 1rem;
  color: rgba(var(--white), 1);

  border: 0;
  border-radius: 0.875rem;

  background-color: rgba(var(--white), 0.08);

  @include mixin.transition(box-shadow);

  outline: none;
}

.input::placeholder {
  color: rgba(var(--white), 0.4);
  opacity: 1;
}

.input::-webkit-search-cancel-button {
  display: none;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button,
.input::-webkit-clear-button {
  appearance: none;
}

.input:-webkit-autofill {
  color: inherit;

  background-color: rgba(var(--white), 0.08);

  &,
  &:hover,
  &:focus,
  &:active {
    transition-delay: 9999s, 9999s;
    transition-property: background-color, color;
  }
}

.input:hover,
.input:focus {
  box-shadow: inset 0 0 0 1px rgba(var(--primary-1), 1);
}

.input:-moz-ui-invalid {
  box-shadow: none;
}

.error {
  position: relative;
}

.error span {
  --lighten: 1;

  position: absolute;
  top: 0.25rem;

  font-size: 0.75rem;
}
.max {
  border-radius: 49px;
  border: 1px solid #ffffff;
  position: absolute;
  right: 1.25rem;
  bottom: 0.7rem;
  padding: 4px 14px;
  width: fit-content;
}
