.btn_default {
  padding: 14px 24px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  height: 48px;
  box-sizing: border-box;
  @media #{$mobile} {
    height: auto;
    min-height: 48px;
  }
}

.default_button {
  @extend .btn_default;
  color: #ffffff;
  background-color: rgba(152, 103, 255, 1);

  &:hover,
  :focus {
    &:not([disabled]) {
      color: #ffffff;
      background-color: rgba(116, 51, 255, 1);
    }
  }

  &:disabled {
    // opacity: 0.3;
    color: #464647;
    background-color: rgba(46, 34, 71, 1);
  }
  &_dark{
    background-color: #211735;
    color: #9867FF;
    border-radius: 10px;
    &:hover,
    :focus {
      &:not([disabled]) {
        color: #ffffff;
        background-color:#9867FF;
      }
    }

  }
}

.notice_button {
  @extend .btn_default;

  font-size: 16px;
  color: #9867FF;
  background-color: transparent;
  border: 1px solid #9867FF;
  &:disabled {
    color: #9867FF;
    background-color: transparent;
    cursor: not-allowed;
  }
}

.primary_button {
  @extend .btn_default;

  background-color: rgba(33, 23, 53, 1);
  border: none;
  color: #9867FF;

  &:hover,
  :focus {
    &:not([disabled]) {
      background-color: rgba(62, 39, 107, 1);
    }
  }

  &:disabled {
    color: #412E6A;
    cursor: not-allowed;
    background-color: rgba(29, 21, 45, 1);
  }
}


.default_button_outlined {
  padding: 14px 24px;
  display: flex;
  justify-content: center;
  border: 1px solid rgba(255, 255, 255, 0.4);
  color: #fff;
  background-color: transparent;
  border-radius: 10px;

  &:hover,
  :focus {
    &:not([disabled]) {
      opacity: 1;
      color: #fff;
      border-color: $primary2;
    }
  }

  &:disabled {
    border-color: $primary1;
    opacity: 0.6;
  }

  // &.white {
  //   border: 1px solid $text1;
  //   color: $text1;

  //   &:hover,
  //   :focus {
  //     color: $primary1;
  //     border-color: $primary1;
  //   }

  //   &:disabled {
  //     color: $text1;
  //     border-color: $text1;
  //     opacity: 0.6;
  //   }
  // }
}
