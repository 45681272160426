@use "../../../../helpers/style/mixin";
@use "../../../../helpers/style/media";

.component {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: calc(var(--spacing) * 2);
  align-items: center;
}

.gear {
  position: relative;
}

.gear::after {
  position: absolute;
  top: 50%;
  left: 50%;
  content: "";

  display: block;
  width: 3rem;
  height: 3rem;

  background-image: url("./assets/logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;

  transform: translate(-50%, -50%);
}

.gear svg {
  transform: rotate(190deg);
}

.path {
  transition-duration: 350ms;
  transition-property: stroke-width;
}

.list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: calc(var(--spacing) * 3);
  grid-row-gap: calc(var(--spacing) * 2);

  margin: 0;
  padding: 0;

  list-style: none;
}

.numbers {
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  align-items: center;
  grid-column-gap: var(--spacing);

  margin: 0;
}

.token {
  display: flex;
  flex-direction: row;
  align-items: center;

  min-height: 1.125rem;
  padding: calc(var(--spacing) * 0.25) var(--spacing);

  font-size: 0.6875rem;

  border-radius: 0.25rem;

  background-color: rgba(var(--white), 0.2);
}

.caption {
  margin: 0;

  @include mixin.transition(color);
}

.text {
  margin: 0;
}
