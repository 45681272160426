.liquidity {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0) 100%), #000000;
    border: 1px solid rgba(255, 255, 255, 0.2);
    width: 512px;
    border-radius: 32px;
    padding: 20px 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 512px;
    width: 100%;

    @media #{$mobile} {
        padding-bottom: 120px;
    }

    &__header {
        font-family: Futura PT;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        margin-bottom: 12px;
    }
    >.default_modal__content {
        font-size: 14px;
        text-align: left;
    }

    &__divider {
        height: 1px;
        background-color: $white2;
        width: 100%;
        margin-top: 28px;
        margin-bottom: 12px;
    }
    &__dividerfull {
        margin-left: -30px;
        width: calc(100% + 60px);
    }
    .btn_close {
        cursor: pointer;
    }
    &__title {
        flex: .7;
        text-align: center;
        margin-bottom: 0;
    }
    .text-p12 {
        font-size: 12px !important;
        margin-bottom: 6px;
    }
    .liq_amount_group {
        display: flex;
        // background-color: rgb(31, 31, 31);
        background: rgba(255, 255, 255, 0.08);
        >div.bridge__input_frame {
            width: 40%;
        }
        >div.main_content {
            width: 60%;
        }
        >.liq_amount_right {
            width: 100%;
            margin: 0;
            height: 46px;
            color: #ffffff;
            border-radius: 10px;
            border: unset;
            padding: 5px 20px;
            border: 1px solid transparent;
            display: flex;
            align-items: center;
            cursor: not-allowed;
            align-items: center;
            font-family: Roboto-Regular;
            font-weight: 500;
            color: #FFFFFF;
            &:hover {
                // background-color: rgb(60, 60, 60);
                background-color: rgb(47, 47, 47);
            }
            >svg {
                margin-right: 10px;
                height: 28px;
                width: auto;
            }

            p {
                margin: auto 0;
            }
        }
        .button_select {
            border-radius: 14px;
            &:hover {
                background-color: rgb(47 47 47);
            }
        }
        &.input_error {
            .enter_amount_input {
                &:hover {
                    border: none;
                }
                &:focus {
                    border: none;
                }
            }
        }
        .main_content {
            > input {
                &:disabled {
                    cursor: not-allowed;
                }
            }
        }
    }
    .md-15 {
        margin-bottom: 15px;
    }
    .error {
        font-size: 12px;
    }
    &__pool-box {
        border: 1px solid rgba(255, 255, 255, .4);
        box-sizing: border-box;
        border-radius: 22px;
        padding: 15px 20px;
        margin-top: 15px;
        >p {
            color: #fff;
            font-size: 14px;
        }
        .pool-info {
            padding-top: 15px;
            font-size: 12px;
            display: flex;
            justify-content: space-between;
            >span:first-child {
              opacity: .6;
            }
            b {
                font-size: 14px;
                margin-right: 15px;
            }
        }
    }
    .main_content {
        >input {
            padding-right: 90px;
        }
    }
    .text-b12 {
        font-size: 12px;
        margin: 0 10px;
        opacity: .6;
    }
    .liquidity__asset {
        justify-content: space-between;
        height: 100%;
        >p {
            flex: auto;
        }
    }
    .asset.asset_selected {
        background-color: #333;
    }

    &__data-panel {
        margin: 10px;
        height: 30px;
        font-size: 18px;
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        .liq_loading {
            &::after {
                display: inline-block;
                animation: ellipsis 1.25s infinite;
                content: '.';
                width: 1em;
                text-align: left;
            }
            @keyframes ellipsis {
                0% {
                content: '.';
                }
                33% {
                content: '..';
                }
                66% {
                content: '...';
                }
            }
        }
    }
    .confirm_modal__loading {
        width: auto;
        margin: 0;
        margin-right: 15px;
    }

    .withdraw {
        width: 100%;
        &__btn-group {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 30px;
            >button {
                flex: .48 1;
            }
        }
        &__pool-box {
            margin-top: 20px;
            .self-info {
                height: 25px;
                margin-top: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                >span:first-child {
                    display: flex;
                    align-items: center;
                    img {
                        height: 20px;
                        margin-right: 10px;
                    }
                }
            }
        }
        &__slider-panel {
            border: 1px solid rgba(255, 255, 255, .4);
            box-sizing: border-box;
            border-radius: 14px;
            margin-bottom: 20px;
            .slider-area {
                padding: 20px 20px 0 20px;
                .slider-tips {
                    font-size: 14px;
                    display: flex;
                    justify-content: space-between;
                    >a {
                        color: #9867FF;
                    }
                }
            }
            .slider-token {
                padding: 0 20px 20px;
            }
        }

        .MuiSlider-track {
            background-color: #fff;
        }
        .MuiSlider-thumb.MuiSlider-thumbColorPrimary {
            background-color: #fff;
        }
        .MuiSlider-rail {
            background: #FFEFEF;
        }
        .per-box {
            margin-top: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .per-title {
                font-size: 36px;
            }
            .per-set-tag {
                font-size: 14px;
                >span {
                    cursor: pointer;
                    display: inline-block;
                    padding: 5px 15px;
                    background: rgba(255, 255, 255, 0.1);
                    border-radius: 14px;
                    margin-right: 5px;
                }
            }
        }
    }
}

.liquidity_item {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    width: 100%;

    p {
        font-size: 14px;
        text-align: left;
        max-width: 438px;
    }

    &__header {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        color: $white6;

        &_tab {
            display: flex;
            &>div:first-child, &>p:first-child {
                padding-left: 20px;
                flex: .275;
            }
            &>div:last-child, &>p:last-child {
                padding-left: 20px;
                flex: .725;
            }
        }

    }
    &__header_box {
        background: #262626;
        border-radius: 10px;
        &>div:first-child {
            background-color: #333;
        }
        &:hover {
            background-color: #222;
        }
    }

    &__content {
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 14px;
        // width: 448px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        padding: 17px 0 20px 0;
        margin-top: 8px;
        ul {
            padding: 0 20px;
            width: 100%;
            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 24px;
                &>p:first-child {
                    color: $white6;
                }
                &>p:last-child {
                    color: white;
                }
            }
        }
    }

    &__buttons {
        display: flex;
        flex-wrap: wrap;
        padding: 20px 20px 0;
        justify-content: space-between;
        border-top: 1px solid $white2;
        margin-top: 16px;

        button {
            width: 48%;
            height: 32px;
        }

        &>button:last-child {
            width: 408px;
            margin-top: 16px;
        }
    }

    .liquidity_asset {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 133.5%;
        width: 100%;
        height: 48px;
        border-radius: 14px;
        // border: 1px solid rgba(255, 255, 255, 0.2);
        display: flex;
        white-space: pre;
        align-items: center;
        color: #ffffff;

        img {
            height: 24px;
            width: fit-content;
            margin-right: 8px;
        }

        p {
        }
    }
}

.liquidity_chain {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 133.5%;
    width: 100%;
    flex: 1;
    height: 48px;
    border-radius: 14px;
    // border: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    color: #ffffff;

    img {
        height: 24px;
        margin-right: 8px;
    }

    p {
    }

    &__manager {
        margin-left: auto;
        margin-right: 0;
        display: flex;
        align-items: center;
        cursor: pointer;

        svg {
            margin-left: 8px;
            margin-right: 18px;
        }

        &:hover {
            svg {

            }
        }
    }
}

.tip_frame {
    width: 100%;
    padding: 12px 20px;
    margin-top: 16px;
    border: 1px solid #9867FF;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;

    .svg {
        width: 16px;
        height: 16px;
    }

    p {
        font-family: Roboto;
        font-size: 13px;
        flex: 1;
        text-align: left;
        margin-left: 16px;
     }
}
