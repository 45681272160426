.component {
}

.balanceWrapper {
  display: grid;
  grid-row-gap: calc(var(--spacing) * 0.5);
  justify-items: flex-start;
}

.balance {
  display: flex;
  align-items: center;

  min-width: 31.5rem;
  min-height: 3rem;
  margin: 0;
  padding: calc(var(--spacing) * 1) calc(var(--spacing) * 2.5);

  box-sizing: border-box;

  border-radius: 0.875rem;

  background-color: rgba(var(--white), 0.08);
}

.purchase {
  margin: 0;
  margin-top: calc(var(--spacing) * 2.5);
}

.button {
  max-width: 25rem;
  margin-top: calc(var(--spacing) * 5);
}

.input {
  outline: none;
  border: none;
  background: none;
  width: 100%;
  color: white;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.title {
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
}

.timer {
  font-family: 'Futura PT';
  font-size: 32px;
  font-weight: 400;
  line-height: 43px;
  letter-spacing: 0em;
  text-align: center;
  color: #9867ff;
}

.timer_button {
  position: absolute;
  top: -42px;
  right: 32px;
  max-width: 188px;
  height: 30px !important;
  min-height: unset !important;
  color: #9867ff !important;
  background-color: transparent !important;
  border: 1px solid #9867ff !important;
}
