.component,
.component::after {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
}

.medium,
.medium::after {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
}

.small,
.small::after {
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
}

.component {
  position: relative;

  font-size: 10px;
  text-indent: -9999em;

  border-left: 1px solid rgba(var(--white), 0.2);

  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
}

.default {
  border-top: 1px solid rgba(var(--primary-1), 1);
  border-right: 1px solid rgba(var(--primary-1), 1);
  border-bottom: 1px solid rgba(var(--primary-1), 1);
}

.white {
  border-top: 1px solid rgba(var(--white), 1);
  border-right: 1px solid rgba(var(--white), 1);
  border-bottom: 1px solid rgba(var(--white), 1);
}

@-webkit-keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
