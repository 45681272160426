@use "../../../../helpers/style/mixin";
@use "../../../../helpers/style/media";

.component {
  --max-width: 34.5rem;

  margin: auto 0;
  padding: calc(var(--spacing) * 4) 0
    calc(var(--spacing) * 4 + var(--header-height));
}

.wrapper {
  position: relative;
}

.wrapper::before {
  --height: 8.125rem;

  position: absolute;
  bottom: calc(var(--height) / -2);
  left: 50%;
  content: '';

  display: block;
  width: calc(100% - var(--gutters) * 2);
  max-width: var(--max-width);
  height: var(--height);

  border-radius: 50%;

  background-color: rgba(var(--white), 0.05);
  filter: blur(100px);

  transform: translateX(-50%);
}

.content {
  position: relative;

  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
  padding: calc(var(--spacing) * 3) calc(var(--spacing) * 4)
    calc(var(--spacing) * 5);

  border: 1px solid rgba(var(--white), 0.2);
  border-radius: 2rem;
}

.title {
  margin: 0;
}

.form {
  margin-top: calc(var(--spacing) * 4);
}

.input {
  margin-right: 48px;
}

.setting{
  position: absolute;
  margin-top: -34px;
  right: 40px;
  cursor: pointer;
}

.label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.submit {
  margin-top: calc(var(--spacing) * 4);
}
