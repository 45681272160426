@use "../../helpers/style/mixin";
@use "../../helpers/style/media";

.primary {
  font-family: var(--primary-font);
}

.secondary {
  font-family:  var(--secondary-font);
}
