.component {
}

.list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: calc(var(--spacing) * 2.5);

  width: 100%;
  margin: 0;
  padding: 0;

  list-style: none;
}

.item {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;

  padding: calc(var(--spacing) * 2.5);

  border-radius: 0.874rem;

  background-color: rgba(var(--white), 0.08);
}

.presentation {
  display: flex;
  justify-content: center;

  margin-top: calc(var(--spacing) * 8.5);
}

.step {
  position: relative;

  text-align: center;

  padding-top: 6.75rem;
}

.contract {
  margin-right: calc(var(--spacing) * 3);
}

.uniswap {
  margin: 0 calc(var(--spacing) * 7.5);
}

.amount {
  margin-left: calc(var(--spacing) * 3);
}

.step::before {
  position: absolute;
  left: 50%;
  content: '';

  display: block;
  width: var(--size);
  height: var(--size);

  border-radius: 50%;

  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;

  transform: translateX(-50%);

  box-shadow: inset 0 2px 2px rgba(var(--white), 0.25);
}

.contract::before {
  --size: 5rem;

  top: 0.5rem;

  background-image: linear-gradient(
      270deg,
      rgba(var(--white), 1) 0.21%,
      rgba(247, 245, 254, 0.505379) 2.48%,
      rgba(209, 200, 251, 0.15) 13.29%,
      rgba(123, 98, 243, 0.06) 49.42%,
      rgba(209, 200, 251, 0.15) 90.07%,
      rgba(var(--primary-1), 1) 100%
    ),
    url('./assets/contract.svg');
}

.uniswap::before {
  --size: 6rem;

  top: 0;

  background-image: linear-gradient(
      270deg,
      rgba(var(--white), 1) 0.21%,
      rgba(247, 245, 254, 0.505379) 2.48%,
      rgba(209, 200, 251, 0.15) 13.29%,
      rgba(123, 98, 243, 0.06) 49.42%,
      rgba(209, 200, 251, 0.15) 90.07%,
      #ff007a 100%
    ),
    url('./assets/uniswap.svg');
}

.amount::before {
  --size: 5rem;

  top: 0.5rem;

  background-image: linear-gradient(
      270deg,
      rgba(var(--white), 1) 0.21%,
      rgba(247, 245, 254, 0.505379) 2.48%,
      rgba(209, 200, 251, 0.15) 13.29%,
      rgba(248, 247, 255, 0.06) 49.42%,
      rgba(209, 200, 251, 0.15) 90.07%,
      #eae0ff 100%
    ),
    url('./assets/etherium.svg');
}

.arrow {
  margin-top: 1.25rem;
}

.button {
  width: 100%;
  max-width: 25rem;
  margin: 0 auto;
  margin-top: calc(var(--spacing) * 8.5);
}

.logo_link {
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
}
