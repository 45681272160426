@use "../../helpers/style/mixin";
@use "../../helpers/style/media";

.component {
  width: 100%;
  max-width: var(--max-width-content);
  margin: 0 auto;
  padding: 0 var(--gutters);

  box-sizing: border-box;
}
