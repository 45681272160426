.claimed {
  display: grid;
  align-content: center;

  height: 100%;
}

.claimedTitle {
  margin: 0;

  text-align: center;
}

.claimedTitle::before {
  position: relative;
  content: "";

  display: block;
  width: 2rem;
  height: 2rem;
  margin: 0 auto;
  margin-top: calc(var(--spacing) * 3.5);
  margin-bottom: calc(var(--spacing) * 1.5);

  background-image: url("./assets/success.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
}

.claimedText {
  margin: 0;
  margin-top: calc(var(--spacing) * 0.5);

  text-align: center;
}

.claimedLink {
  margin: 0 auto;
  margin-top: calc(var(--spacing) * 2);
}

.claimedButton {
  margin: 0;
  margin-top: calc(var(--spacing) * 3);
}

