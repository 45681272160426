.mini_menu {
  position: absolute;
  top: 100%;
  left: 0;
  padding: 60px 25px;
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #000000;
  width: 100%;
  z-index: 2;
  min-height: calc(100vh - 100px);
  border-radius: 32px;
}

.button_select {
  height: fit-content !important;
  position: relative!important;
  font-size: 16px;
  .icon {
    margin-right: 10px;
    height: 24px;
  }
  .selected__button {
    background-color: #2f2f2f;
  }
  button {
    margin: 0;
    width: 100%;
    height: 46px;
    color: #ffffff;
    border-radius: 10px;
    border: unset;
    padding: 0 20px;
    margin-right: 20px;
    // border: 1px solid transparent;
    display: flex;
    justify-content: space-between;
    font-family: Roboto-Regular;
    font-weight: 500;
    color: #FFFFFF;
    border: 1px solid transparent;
    &:hover {
      color: #ffffff;
      background-color: rgba(255, 255, 255, 0.05);
    }
    &:disabled {
      opacity: .6;
    }
  }
  span {
    // font-family: Roboto-Regular;
    font-style: normal;
    font-weight: normal;
    // font-size: 13px;
    line-height: 148.69%;
    // color: rgba(255, 255, 255, 0.6);
    &.text_16{
      font-size: 16px;
    }
  }
  .option_frame {
    position: absolute;
    display: none;
    flex-direction: column;
    width: 100%;
    max-height: calc(100vh - 328px);
    border-radius: 14px;
    overflow: hidden;
    overflow-y: auto;
    z-index: 2;
    background-color: #1f1f1f;
    color: #ffffff;
    margin-top: 8px;
    & button:last-child {
      border-bottom-left-radius: 14px;
      border-bottom-right-radius: 14px;
    }
    & button:first-child {
      border-top-left-radius: 14px;
      border-top-right-radius: 14px;
    }
    &.is_open {
      display: flex;
      height: fit-content;
    }
    & > div {
      height: auto;
    }
  }
  .select_option {
    border: none;
    border-radius: unset;
    // border: 1px solid transparent;
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);
    color: #ffffff;
    padding: 14px;
    background-color: transparent;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 46px;

    input {
      width: fit-content;
      margin-right: 25px;
    }

    svg {
      height: 28px;
    }

    .checked {
      margin: 0 18px 0 4px;
    }

    .dis_checked {
      color: transparent;
    }

    :hover,
    :focus,
    :active {
      background-color: rgba(255, 255, 255, 0.12);
    }
    & .selected {
      background-color: rgba(255, 255, 255, 0.12);
    }
  }
}
