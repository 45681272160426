$miniHeaderHeight: 83px;

$xs: 576px;
$sm: 768px;
$md: 992px;
$lg: 1200px;

@mixin breakpoint($breakpoint) {
  @media all and (max-width: $breakpoint) {
    @content;
  }
}
