@import '../../assets/css/variables';

.farm {
  grid-template-rows: auto;
  grid-template-columns: 50% 50%;
  grid-gap: 20px;
  display: grid;
  @media #{$mobile} {
    grid-template-columns: 100%;
  }
}
