@use "../../helpers/style/mixin";
@use "../../helpers/style/media";

.component {
  --removed-body-scroll-bar-size: 0px;

  position: fixed;
  z-index: 100;
  top: 50%;
  left: calc(50% - (var(--removed-body-scroll-bar-size) / 2));
  right: auto;

  width: calc(100% - (var(--removed-body-scroll-bar-size)) + 0px);
  height: auto;
  max-width: 31.25rem;
  margin: 0 auto;

  box-sizing: border-box;

  transform: translate(-50%, -35%);

  opacity: 0;

  pointer-events: none;
  visibility: hidden;

  transition-timing-function: linear;
  transition-duration: 250ms, 250ms;
  transition-property: transform, opacity;

  outline: none;

  @include mixin.until(media.$sm) {
    height: var(--window-height);
    padding: 0;
  }
}

.animation {
  transform: translate(-50%, -50%);
  opacity: 1;

  transition-timing-function: linear;
  transition-duration: 250ms, 500ms;
  transition-property: transform, opacity;
}

.visible {
  pointer-events: all;
  visibility: visible;
}

.container {
  position: relative;

  max-height: calc(86vh - 2.5rem);

  border: 1px solid rgba(var(--white), 0.2);
  border-radius: 2.5rem;

  background: linear-gradient(283.31deg, rgba(255, 255, 255, 0.18) -2.53%, rgba(255, 255, 255, 0.17) 18.66%, rgba(255, 255, 255, 0) 98.68%), #000000;

  overflow: hidden;

  outline: none;

  @include mixin.until(media.$sm) {
    height: 100%;
    max-height: none;

    border-radius: 0;
  }
}

.close {
  position: absolute;
  top: calc(var(--spacing) * 3);
  right: calc(var(--spacing) * 3);
}

.content {
  display: grid;

  min-height: 16.875rem;
  padding: 1.5rem 2.5rem 2.5rem;

  box-sizing: border-box;
}
