.component {
  display: grid;
  align-items: center;
}

.text {
  text-align: center;
}

.text::before {
  position: relative;
  content: "";

  display: block;
  width: 2rem;
  height: 2rem;
  margin: 0 auto;
  margin-bottom: calc(var(--spacing) * 2.5);

  background-image: url("./assets/error.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
