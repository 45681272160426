.deploy {
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  // margin-top: -24px;
}

@media #{$noMobile} {
  .default_modal {
    width: 552px;
  }
}

.modal_init {
  @media #{$noMobile} {
    width: 552px;
  }
  padding-top: 52px;

  button {
    height: 72px;
    width: 488px;
    border-radius: 10px;
    flex-direction: column;
    background:#211735;
    font-size: 12px;
    @media #{$mobile} {
      width: 100%;
      word-break: break-all;
    }
    p {
      max-width: 100%;
    }
    &:hover {
      color: #ffffff;
      background: #9867ff;
      p:first-child{
        color: #ffffff;
      }
      &:disabled{
        background:#211735;
        p:first-child{
         color: #9867ff;
        }
      }
    }

    p:first-child {
      font-size: 16px;
      color: #9867ff;
      margin-bottom: 6px;

    }

    &:disabled {
      color: #ffffff;
    }
  }
}

.modal_add_token {
  padding: 24px 32px 32px 32px;
  font-size: 14px;

  p {
    text-align: left;
  }

  &__body {
    width: 100%;
    margin-top: -20px;
    margin-bottom: -20px;

    & > * {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}

.modal_added {
  font-size: 16px;
  padding: 52px 32px 32px 32px;

  a {
    margin-top: 16px;
    color: rgba(152, 103, 255, 1);
  }

  button {
    margin-top: 16px;
    width: 100%;
  }
}

.deploy .step_frame {
  height: fit-content;
  margin: 0;
  margin-bottom: 28px;

  .step_circle {
    border: 1px solid #9c6dff;
    width: 24px;
    height: 24px;
    background-color: transparent;
    flex-shrink: 0;

    @media #{$mobile} {
      width: 16px;
      height: 16px;
    }
  }
  p{
    flex-shrink: 0;
  }

  .divider {
    opacity: 0.2;
    margin: 0 8px
  }

  svg {
    color: #9c6dff;
  }

  div {
    width: 16px;
    height: 1px;
    background-color: #ffffff;
  }
}

.check_frame {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 0;
  padding: 6px;
  & .divider{
    position: absolute;
    top:-8px;
    left: 0;
    width: calc(100% + 48px);
    margin:0 -24px;
  border-top:   1px solid rgba(255, 255, 255, 0.2);
  }

}

.copy_frame {
  width: 100%;
  margin-top: -12px;
  margin-bottom: -12px;

  & > * {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  li {
    width: 100%;
    height: 48px;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 14px;
    display: flex;
    align-items: center;
    padding-left: 20px;

    p {
      margin-left: 8px;
    }
  }
}

.mappings {
  margin-top: 4px;
  margin-bottom: 8px;

  & > * {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  & .default_button{
    height: 48px
  }
}

.mapping_frame {

  &__mapping {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 4px;

    div {
      width: 292px;
      height: 48px;
      background: rgba(255, 255, 255, 0.08);
      border-radius: 14px;
      display: flex;
      align-items: center;
      padding-left: 20px;
      font-family: Roboto-regular;
    }

    button {
      width: 180px;
    }
  }
}

.deploy .extra{
  width: 100%
}
